import React from 'react';
import ListItem, { ListItemProps, ListItemState } from './ListItem';

// Reconsider the name of this module


interface ActionListProps
{
    type: ActionListType,
    items: ListItemProps[],
    compact?: boolean,
    selectable?: boolean,
    isLoading?:boolean,
    isEmpty?:boolean,
}
 
interface ActionListState
{
    selectedIndex: number;
}

export enum ActionListType
{
    Normal,
    Flush
}

export default class ActionList extends React.Component<ActionListProps, ActionListState>
{
    public static defaultProps =
        {
            type: ActionListType.Normal,
            items: [{ text: "empty" }],
            compact: false,
            selectable: true,
            isEmpty:false
        };

    constructor(props: ActionListProps)
    {
        super(props);

        this.state =
        {
            selectedIndex: -1
        };
    }

    getClasses(): string
    {
        var classes: string[] = [];

        if (this.props.type === ActionListType.Flush)
            classes.push('list-group-flush');

        if (this.props.compact)
            classes.push('list-group-compact');

        if (!this.props.selectable)
            classes.push('disabled');
        if (this.props.isLoading)
            classes.push('loading')
        return classes.join(' ');
    }

    getState(item: ListItemProps, number: number): ListItemState | undefined
    {
        if (!this.props.selectable)
            return ListItemState.None;

        return this.state.selectedIndex === number ? ListItemState.Active : item.state;
    }

    onClick(index: number): boolean
    {
        if (this.props.selectable)
        {
            this.setState
                ({
                    selectedIndex: index
                });
        }

        return true;
    }

    render(): React.ReactNode
    {
        return (
            <React.Fragment>
                <div className={`list-group ${(this.props.isEmpty) && 'd-none'} ${this.getClasses()}`}>
                    <div className="skeleton list-group-item">&nbsp;</div>
                    <div className="skeleton list-group-item">&nbsp;</div>
                    <div className="skeleton list-group-item">&nbsp;</div>
                    <div className="skeleton list-group-item">&nbsp;</div>
                    {
                    
                        this.props.items.map((item: ListItemProps, number: number) => (
                            <ListItem {...item} key={number} state={this.getState(item, number)} onClick={(event: React.MouseEvent) => this.onClick(number) && (item.onClick && item.onClick(event, item))} />
                        ))
                    }

                </div>

                <div className={this.props.isEmpty ? "empty-list" : "d-none"}>
                        <i className="fal fa-folder-open"></i>
                        No Items found
                    </div>
            </React.Fragment>
        );
    }
}