import React, {useState} from 'react';
import { v4 as uuidv4 } from "uuid";

import { GridContainer, LayoutColumn } from '../Layout'
import { LocationSearch } from './'
import { Map } from '../Map'
import { BasicColors } from '../../components/GlobalEnums';

interface Props {
    accessToken: string,
    address?: any | Address
    limit?: number
    country?: string
    types?: string
    proximity?: any 
    boxed?: any
    highRiskAreas?: Array<string>
    topLayer?: any
    bottomLayer?: any
    mapHeight?: string
    location?: (v: any) => void
    riskStatus?: (v: any) => void
    color?: BasicColors
    gradientLayer?: boolean
    icon?: string
    label?: string
}

interface Address {
    riskLevel?: string
    riskMessage?: string
    text?: string
    longitude?: string 
    latitude?: string
    zoom?: number 
    scrollZoom?: boolean
}

export const AddressSearch: React.FC<Props> = ({ 
        accessToken,
        topLayer, 
        bottomLayer, 
        address, 
        limit, 
        country, 
        types, 
        proximity, 
        boxed, 
        highRiskAreas, 
        mapHeight, 
        color, 
        gradientLayer,
        icon,
        label,
        location, 
        riskStatus
    }) => {
    const randomId = uuidv4();
    const { riskLevel, text, longitude, latitude, zoom, scrollZoom } = address;
    const [state, setState] = useState<Address>({
        riskLevel: riskLevel,
        text: text,
        longitude: longitude,
        latitude: latitude,
        zoom: zoom,
        scrollZoom: scrollZoom,
    });

    const changeDestination = (value: any) => {
        if (location) {
          location(value)
        }

        let riskLevelStatus: string;
        if (riskStatus && highRiskAreas) {
            riskLevelStatus = riskLevelCheck(value.text, highRiskAreas)

            if (riskLevelStatus === 'warning') {
              riskStatus({level: 'warning', status: true})
            }  else {
              riskStatus({level: '', status: false})
            }
        }
           
        setState((Prev: any) => ({
          ...Prev,
          riskLevel: riskLevelStatus || '',
          text: value.text,
          longitude: `${value.coordinates.lng}`,
          latitude: `${value.coordinates.lat}`,
          zoom: 12
        }))
    }

    const riskLevelCheck = (value: any, risk: any) => {
        for (let i = 0; i < risk.length; i++) {
            if (value.toLowerCase().includes(risk[i].toLocaleLowerCase())) {
                return 'warning'
            }
        }
        return ''
    }

    return (
        <GridContainer showGutters>
            <LayoutColumn colSize={12}>
                <div className="map">
                    <div className="top-layer">
                        <div className="form-group">
                            { label && <label className={`${color}-text`}>{label}</label> }
                            <LocationSearch 
                                name={`destination_${randomId}`} 
                                icon={icon}
                                onChange={(v: any) => changeDestination(v)} 
                                required={false}
                                value={text}
                                limit={limit}
                                country={country}
                                types={types}
                                proximity={proximity}
                                boxed={boxed}
                                accessToken="pk.eyJ1Ijoic3BldHNpbmciLCJhIjoiY2tpMXlwOHdpMHEzdjJybXN6YmNzZ205eCJ9.ndS2CNk-eJa4C7kQrK-40g"
                            />
                        </div>
                        {topLayer}
                    </div>
                    <div className="bottom-layer">
                        {bottomLayer}
                    </div>
                    {   gradientLayer && 
                        <div className="gradient" >
                            <div className={!(!state.riskLevel) ? `overlay ${state.riskLevel}` : "overlay"} />
                        </div>
                    }
                    <Map 
                        longitude={state ? state.longitude : longitude} 
                        latitude={state ? state.latitude : latitude} 
                        zoom={state ? state.zoom : zoom} 
                        scrollZoom={state ? state.scrollZoom : scrollZoom} 
                        height={mapHeight}
                        accessToken={accessToken} />
                </div>
            </LayoutColumn>
        </GridContainer>  
    );
}

AddressSearch.defaultProps = {
    accessToken: "pk.eyJ1Ijoic3BldHNpbmciLCJhIjoiY2tpMXlwOHdpMHEzdjJybXN6YmNzZ205eCJ9.ndS2CNk-eJa4C7kQrK-40g",
    address: {
        riskLevel: '',
        riskMessage: '',
        text: 'MIT, Cambridge, Massachusetts 02139, United States',
        longitude: '-71.09' ,
        latitude: '42.36',
        zoom: 8,
        scrollZoom: false,
    },
    gradientLayer: false
}