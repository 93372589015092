import React from 'react';

export interface PagingDotProps
{
    url?:string,
    index?: number, //Used by PagingContainer
    selected?:boolean,
    onClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, index: number) => void
}
 
export default class PagingDot extends React.Component<PagingDotProps>
{
    public static defaultProps =
        {
            selected:false
        };


    getClasses(): string
    {
        var classes: string[] = [];

        if (this.props.selected)
            classes.push('selected');


        return classes.join(' ');
    }

    render(): React.ReactNode
    {
        return (
           <a onClick={(e) => this.props.onClick && this.props.onClick(e, this.props.index ?? 0)} className={`paging-dot ${(this.getClasses())}`}></a>
        );
    }
}