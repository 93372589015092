import React from 'react';

export interface LabelProps
{
    //required
    text: string,
   
    //optional
    target?: string
    icon?: string,
}
 
export default class Label extends React.Component<LabelProps>
{
    render(): React.ReactNode 
    {
        return (
            //we are still missing the icon
            <label htmlFor={`{this.props.target}`}>{this.props.text}</label>
        );
    }
}