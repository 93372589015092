import React from 'react';
import {BasicColors} from '../components/GlobalEnums';
export interface ContainerProps
{
    contents: any,
    alignment?: string,
    raised?:boolean,
    color?: BasicColors,
    className?: string
}
 
export default class Container extends React.Component<ContainerProps>
{
    public static defaultProps =
        {
            alignment:"left",
            raised:false
        };

        getClasses(): string
        {
            var classes: string[] = [];
    
            if (this.props.raised)
                classes.push('raised');

            if (this.props.color == "red")
                classes.push('red');

            if (this.props.color == "white")
                classes.push('white');

            if (this.props.color == "green")
                classes.push('green');

            if (this.props.color == "blue")
                classes.push('blue');

            if (this.props.color == "grey")
                classes.push('grey');

            if (this.props.color == "orange")
                classes.push('orange');

            if (this.props.color == "purple")
                classes.push('purple');

            if (this.props.color == "yellow")
                classes.push('yellow');
    
            return classes.join(' ');
        }
    
       

    render(): React.ReactNode
    {

        return (
            // This item will have to be a style element. It's not wrong, it's a flexibility we afford

                <div className={`card ${(this.getClasses())} text-${this.props.alignment} ${this.props.className}`}>
                    {
                        this.props.contents
                    }
                </div>
           
        );


    }
}