import React from "react";
import { BasicColors } from "./GlobalEnums";
import HelpIcon, { HelpIconProps } from "../components/HelpIcon";

export interface TextProps
{
    content: string;
    bold?: boolean;
    italic?: boolean;
    padded?: boolean;
    color?: BasicColors;
    type: TextType;
    icon?: string;
    help?: HelpIconProps;
}

export enum TextType
{
    Body,
    Heading1,
    Heading2,
    Heading3,
    Heading4,
    Heading5,
    Small,
    Subscript,
    Supscript,
}

export default class Text extends React.Component<TextProps> {

    public static defaultProps = {
        icon: "",
        text: "Heading",
        type: TextType.Body,
        padded: false,
    };

    getClasses(): string
    {
        var classes: string[] = [];

        // I strongly doubt wether these IFs need to be here
        if (this.props.color === "blue") classes.push("text-secondary");
        else if (this.props.color === "grey") classes.push("text-muted");
        else if (this.props.color === "red") classes.push("text-primary");
        else if (this.props.color === "green")
            classes.push("text-success");
        else if (this.props.color === "orange")
            classes.push("text-warning");
        else if (this.props.color === "purple")
            // this one needs to be better
            classes.push("text-info");
        else if (this.props.color === "white") classes.push("text-white");

        if (this.props.bold) classes.push("lead");

        return classes.join(" ");
    }

    render(): React.ReactNode
    {
        if (this.props.type === TextType.Body)
        {
            return (
                <p className={`${this.getClasses()} ${this.props.padded && "my-3"}`}>
                    {this.props.content}
                </p>
            );
        }

        // CAN SOMEONE PLEASE REFACTOR THESE FIVE?
        else if (this.props.type === TextType.Heading1)
        {
            return (
                <h1 className={`${this.getClasses()} ${this.props.padded && "my-3"}`}>
                    {this.props.icon !== "" && (
                        <i className={`fal fa-${this.props.icon}`}></i>
                    )}
                    {this.props.content}
                    {this.props.help && <HelpIcon {...this.props.help} />}
                </h1>
            );
        }
        else if (this.props.type === TextType.Heading2)
        {
            return (
                <h2 className={`${this.getClasses()} ${this.props.padded && "my-3"}`}>
                    {this.props.icon !== "" && (
                        <i className={`fal fa-${this.props.icon}`}></i>
                    )}
                    {this.props.content}
                    {this.props.help && <HelpIcon {...this.props.help} />}
                </h2>
            );
        }
        else if (this.props.type === TextType.Heading3)
        {
            return (
                <h3 className={`${this.getClasses()} ${this.props.padded && "my-3"}`}>
                    {this.props.icon !== "" && (
                        <i className={`fal fa-${this.props.icon}`}></i>
                    )}
                    {this.props.content}
                    {this.props.help && <HelpIcon {...this.props.help} />}
                </h3>
            );
        }
        else if (this.props.type === TextType.Heading4)
        {
            return (
                <h4 className={`${this.getClasses()} ${this.props.padded && "my-3"}`}>
                    {this.props.icon !== "" && (
                        <i className={`fal fa-${this.props.icon}`}></i>
                    )}
                    {this.props.content}
                    {this.props.help && <HelpIcon {...this.props.help} />}
                </h4>
            );
        }
        else if (this.props.type === TextType.Heading5)
        {
            return (
                <h5 className={`${this.getClasses()} ${this.props.padded && "my-3"}`}>
                    {this.props.icon !== "" && (
                        <i className={`fal fa-${this.props.icon}`}></i>
                    )}
                    {this.props.content}
                    {this.props.help && <HelpIcon {...this.props.help} />}
                </h5>
            );
        }

    }
}
