import React, { useCallback, Fragment, useState, useEffect, FC } from 'react';
import { useDropzone } from 'react-dropzone';
import { ButtonType, ButtonProps, ButtonState } from '../Button/Button';
import { ButtonBar, ButtonBarType } from '../Button';
import { Text } from '../..';
import { FileIcon } from './FileIcon';

interface IFileUploadProps {
    acceptedMimeTypes: string[];
    isLoading?: boolean;
    onConfirm: (file: any) => void;
    onChange?: (files: File[]) => void;
    maxSize?: number;
    maxSizeErrorText?: string;
    text?: string;
    multiple?: boolean;
    displayButtons?: boolean;
    clearFilesAfterConfirm?: boolean;
}

export const FileUpload: FC<IFileUploadProps> = (props) => {
    const [myFiles, setMyFiles] = useState<File[]>([]);
    const [myConfirmedFiles, setMyConfirmedFiles] = useState<File[]>([]);
    const [loading, setLoading] = useState(false);
    const [sizeError, setSizeError] = useState(false);

    useEffect(() => {
        if (props.isLoading !== undefined) setLoading(props.isLoading);
    }, [props.isLoading]);

    useEffect(() => {
        props.onChange && props.onChange(myConfirmedFiles);
    }, [myConfirmedFiles]);

    const onDrop = useCallback(
        (acceptedFiles) => {
            //@ts-ignore

            if (props.displayButtons === false) {
                // setMyConfirmedFiles([...myFiles, ...acceptedFiles]);
                acceptedFiles.map((file: File) => {
                    onSubmit(file);
                });

                if (props.clearFilesAfterConfirm) {
                    props.onChange && props.onChange(acceptedFiles);
                }
            } else {
                setMyFiles([...myFiles, ...acceptedFiles]);
            }
        },
        [myFiles]
    );

    const acceptedMimeTypesString = props.acceptedMimeTypes.join(',');
    const { getRootProps, getInputProps, fileRejections } = useDropzone({
        accept: acceptedMimeTypesString,
        maxSize: props.maxSize,
        multiple: props.multiple,
        onDrop,
    });

    const removeFile = (file: File) => () => {
        const newFiles = [...myFiles];
        //@ts-ignore
        newFiles.splice(newFiles.indexOf(file), 1);
        setMyFiles(newFiles);
    };

    const removeConfirmedFile = (index: number) => {
        const newFiles = [...myConfirmedFiles];

        newFiles.splice(index, 1);
        setMyConfirmedFiles(newFiles);
    };

    const removeAll = () => {
        setMyFiles([]);
        setMyConfirmedFiles([]);
    };

    const onSubmit = (file: File) => {
        props.onConfirm(file);
        if (props.clearFilesAfterConfirm) {
            removeAll();
        } else {
            setMyConfirmedFiles((prev) => [...prev, file]);
            setMyFiles((prev) => {
                const newFiles = [...prev];
                //@ts-ignore
                newFiles.splice(newFiles.indexOf(file), 1);
                return newFiles;
            });
        }
    };

    // We need this upload control to show an icon per mime type, for upload needs great ux because it can be real stupid.
    //NB We need this one to be for SINGLE file uploads only, not multiple!!!!
    const files = myFiles.map((file: any) => {
        const defaultButtonData: ButtonProps[] = [
            {
                icon: 'times',
                text: '',
                type: ButtonType.Secondary | ButtonType.Small | ButtonType.Ghost,
                onClick: removeFile(file),
                state: ButtonState.Enabled,
            },
            {
                icon: 'check',
                text: 'Confirm',
                type: ButtonType.Secondary | ButtonType.Small,
                onClick: () => onSubmit(file),
                state: ButtonState.Enabled,
            },
        ];

        return (
            <Fragment>
                <div className='dropzone confirm-area'>
                    <div key={file.path}>
                        <i className='fal fa-file-spreadsheet'></i>
                        <div className='py-2'>
                            <b>{file.path}</b>
                            <br />
                            {file.size}
                        </div>
                    </div>
                    {(props.displayButtons === undefined || props.displayButtons) && <ButtonBar type={ButtonBarType.Padded} buttons={defaultButtonData} />}
                </div>
            </Fragment>
        );
    });

    const fileRejectionItems = fileRejections.map(({ file, errors }) => (
        <Fragment>
            {errors.map((e) => (
                <Text content={props.maxSizeErrorText ? props.maxSizeErrorText : e.message} color={'red'} />
            ))}
        </Fragment>
    ));

    return (
        <React.Fragment>
            {!loading ? (
                files.length === 0 ? (
                    <>
                        <div {...getRootProps({ className: 'dropzone upload-area' })}>
                            <input {...getInputProps()} />
                            <div className='content'>
                                <i className='fal fa-paperclip'></i>
                                {/* Needs to be our icon component */}
                                <div>{props.text}</div>
                            </div>
                            <aside>{fileRejectionItems}</aside>
                        </div>
                    </>
                ) : (
                    files
                )
            ) : (
                <div className='dropzone loading'>
                    <div className='content'>
                        <div className='icon'>
                            <i className='fas fa-cloud-upload-alt'></i>
                            {/* Needs to be our icon component */}
                        </div>
                        <div className='text'>Processing...</div>
                    </div>
                </div>
            )}
            {!!myConfirmedFiles.length && (
                <div className='d-flex flex-wrap mt-3 uploaded-files'>
                    {myConfirmedFiles.map((file: File, index: number) => (
                        <FileIcon
                            text={file.name}
                            type={file.type}
                            onRemove={() => {
                                // alert(`Remove ${file.name} ${index}`);
                                removeConfirmedFile(index);
                            }}
                            showRemoveIcon
                        />
                    ))}
                </div>
            )}
        </React.Fragment>
    );
};

FileUpload.defaultProps = {
    text: 'Drop your file(s) here',
    isLoading: false,
    maxSize: 200000000,
    multiple: false,
    clearFilesAfterConfirm: true,
};
