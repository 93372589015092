import React from 'react';
import { Icon, Text } from '../..';
import { Button, ButtonType } from '../Button';
import { TextType } from '../Text';

interface Props {
    text: string;
    type: string;
    showRemoveIcon?: boolean;
    onRemove?: () => void;
}

export const FileIcon: React.FC<Props> = ({ text, type, showRemoveIcon, onRemove }) => {
    const getIcon = () => {
        if (type.indexOf('image') !== -1) {
            return 'file-image';
        }
        if (type.indexOf('msword') !== -1 || type.indexOf('wordprocessingml') !== -1) {
            return 'file-word';
        }
        if (type.indexOf('excel') !== -1 || type.indexOf('spreadsheetml') !== -1) {
            return 'file-excel';
        }
        if (type.indexOf('pdf') !== -1) {
            return 'file-pdf';
        }

        return 'file';
    };

    return (
        <div className='d-flex file-icon-container uploaded-file'>
            <Icon icon={getIcon()} type='regular' />
            <Text content={text} type={TextType.Body} />
            {showRemoveIcon && <Button type={ButtonType.Ghost} icon='times-circle' iconType={'solid'} onClick={onRemove} />}
        </div>
    );
};

FileIcon.defaultProps = {
    showRemoveIcon: false,
};
