import React from 'react';
import PagingDot, { PagingDotProps } from './PagingDot';

export interface PagingContainerProps
{
    items: PagingDotProps[];
}
 
export default class PagingContainer extends React.Component<PagingContainerProps>
{
    render(): React.ReactNode
    {
        return (

           <div className="paging-container">
               {
                    
                    this.props.items.map((item: PagingDotProps, number: number) => (
                        <PagingDot {...item} index={number} />
                    ))
                }
           </div>

        );
    }
}