import React, { useState, useEffect, KeyboardEventHandler } from 'react';
import './picker.css';
import { DatePicker, Day, DayRange } from './CalendarDisplay';
import { useForm } from 'react-hook-form';
import { Validation } from '../Form';
import Cleave from 'cleave.js/react';
import moment from 'moment';
import { ValidationContainer } from '../Form/ValidationContainer';

export type Theme = 'Blue' | 'Pink' | 'Green'; // we will need a universal colours that are part of the atlas theme, this component should not determine it's own colours
export interface ValidationProps {
    message: string;
    type: ValidationType;
    active: boolean;
}
export enum ValidationType {
    Required,
}

export interface ICalendarInputProps {
    id: string;
    value?: any;
    onChange: any;
    shouldHighlightWeekends?: boolean;
    format?: string;
    maxDate?: Day;
    minDate?: Day;
    className?: string;
    placeholder?: string;
    isRangeSelected?: boolean;
    theme?: Theme;
    isMultiSelect?: boolean;
    disabledDays?: any;
    required?: boolean;
    clearable?: boolean;
    yearRequired?: boolean;
    readonly?: boolean;
    validation?: ValidationProps;
    register?: any; // https://react-hook-form.com/api#register
    errors?: any; // https://react-hook-form.com/api#errors
    setValue?: any; // https://react-hook-form.com/api#setValue
}

export const CalendarInput: React.FC<ICalendarInputProps> = (props) => {
    let { register, errors, setValue } = useForm({
        mode: 'onChange',
    });

    if (register) register = register;

    if (props.errors) errors = props.errors;

    if (props.setValue) setValue = props.setValue;

    const [defaultDate, setDefaultDate]: any = useState(null);
    const [dateDisplay, setDateDisplay]: any = useState('');

    const getDefaultDate = (value: any, isRangeSelected?: boolean, isMultiSelect?: boolean) => {
        if (value === undefined || value === null) {
            if (isRangeSelected) {
                let defaultDate: DayRange = {
                    from: null,
                    to: null,
                };
                setDefaultDate(defaultDate);
                return defaultDate;
            }
            if (isMultiSelect) {
                let defaultDate: Array<Day> = [];
                setDefaultDate(defaultDate);
                return defaultDate;
            }
            let defaultDate = null;
            setDefaultDate(defaultDate);
            return defaultDate;
        }

        if (value instanceof Date) {
            value = {
                day: value.getDate(),
                month: value.getMonth() + 1,
                year: value.getFullYear(),
            };
        }

        if (value.from !== undefined && value.to !== undefined) {
            setDefaultDate(value);
            return value;
        }

        if (value !== undefined && new Date(value) instanceof Date) {
            let year: number = 0;
            let month: number = 0;
            let day: number = 0;

            if (value.indexOf('-') > -1) {
                year = value.split('-')[0];
                month = value.split('-')[1];
                day = value.split('-')[2];
            }

            value = new Date(year, month - 1, day, 0, 0, 0);

            value = {
                day: value.getDate(),
                month: value.getMonth() + 1,
                year: value.getFullYear(),
            };
        }

        if (isRangeSelected && value.type !== 'DayRange') {
            let toDate = new Date(value.year, value.month - 1, value.day, 0, 0, 0);
            toDate.setDate(toDate.getDate() + 3);
            let toValue = {
                day: toDate.getDate(),
                month: toDate.getMonth() + 1,
                year: toDate.getFullYear(),
            };
            let defaultDate = {
                from: value,
                to: toValue,
            };

            setDefaultDate(defaultDate);
            return defaultDate;
        }

        if (isMultiSelect && value.type !== 'Day[]') {
            let defaultDate = [];
            defaultDate.push(value);
            setDefaultDate(defaultDate);
            return defaultDate;
        }

        setDefaultDate(value);
        return value;
    };

    const handleChange = (date: any) => {
        setDefaultDate(date);
        let displayDate = updateDisplay(date);
        setDateDisplay(displayDate);
        if (!props.isRangeSelected && !props.isMultiSelect) {
            if (props.yearRequired === undefined || props.yearRequired) {
                let newDate = moment(`${date.year}-${date.month}-${date.day}T00:00:00`, 'YYYY-MM-DDTHH:mm:ss').format('YYYY-MM-DD');
                props.onChange(newDate);
            } else {
                props.onChange(`${date.month}-${date.day}`);
            }
        } else {
            props.onChange(date);
        }
    };

    const isValidDate = (dateString: string, yearRequired: boolean) => {
        // First check for the pattern
        if (yearRequired && !/^\d{1,2}\/\d{1,2}\/\d{2,4}$/.test(dateString)) return false;
        else if (!yearRequired && !/^\d{1,2}\/\d{1,2}$/.test(dateString)) return false;
        // Parse the date parts to integers
        var parts = dateString.split('/');
        var day = parseInt(parts[1], 10);
        var month = parseInt(parts[0], 10);
        var monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
        if (yearRequired) {
            let yearValue = parts[2].length === 2 ? `20${parts[2]}` : parts[2];
            var year = parseInt(yearValue, 10);

            // Check the ranges of month and year
            if (year < 1000 || year > 3000 || month == 0 || month > 12) return false;
            // Adjust for leap years
            if (year % 400 == 0 || (year % 100 != 0 && year % 4 == 0)) monthLength[1] = 29;
        } else {
            monthLength[1] = 29;
        }
        // Check the range of the day
        return day > 0 && day <= monthLength[month - 1];
    };

    const onKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (props.isMultiSelect && props.isRangeSelected) {
            console.log('Manual entry not supported for multiselect or date ranges');
        }
        let val = e.currentTarget.value;
        setDateDisplay(val);

        if (isValidDate(val, props.yearRequired === undefined ? true : props.yearRequired)) {
            var parts = val.split('/');
            var day = parseInt(parts[1], 10);
            var month = parseInt(parts[0], 10);
            let yearValue = parts[2].length === 2 ? `20${parts[2]}` : parts[2];
            var year = parseInt(yearValue, 10);

            let date = moment(`${year}-${month}-${day}T00:00:00`, 'YYYY-MM-DDTHH:mm:ss').format('YYYY-MM-DD');
            let dateValue = getDefaultDate(date, props.isRangeSelected, props.isMultiSelect);
            props.onChange(date);
            setDefaultDate(dateValue);
        }
    };

    const getPrimaryColor = (theme: Theme) => {
        switch (theme) {
            case 'Blue':
                return '#20447C';
            case 'Green':
                return '#0B393C';
            case 'Pink':
            default:
                return '#E3124C';
        }
    };

    const getLighterColor = (theme: Theme) => {
        switch (theme) {
            case 'Blue':
                return 'rgba(32, 68, 124, 0.4)';
            case 'Green':
                return 'rgba(11, 57, 60, 0.4)';
            case 'Pink':
            default:
                return 'rgba(227, 18, 76, 0.4)';
        }
    };

    useEffect(() => {
        if (defaultDate === null) {
            let startupValue = getDefaultDate(props.value, props.isRangeSelected, props.isMultiSelect);
            setDefaultDate(startupValue);
            let displayDate = updateDisplay(startupValue);
            setDateDisplay(displayDate);
        }

        let element: any = document.getElementById(props.id);
    });

    const updateDisplay = (selectedDate: any) => {
        if (selectedDate === null || selectedDate === '' || selectedDate === undefined) {
            return undefined;
        }
        if (selectedDate.from !== undefined || selectedDate.to !== undefined) {
            let returnString = '';
            if (selectedDate.from !== null && selectedDate.from !== undefined && selectedDate.from !== '') {
                returnString += `from ${selectedDate.from.month < 10 ? '0' + selectedDate.from.month : selectedDate.from.month}/${
                    selectedDate.from.day < 10 ? '0' + selectedDate.from.day : selectedDate.from.day
                }/${selectedDate.from.year} `;
            }
            if (selectedDate.to !== null && selectedDate.to !== undefined && selectedDate.to !== '') {
                returnString += `to ${selectedDate.to.month < 10 ? '0' + selectedDate.to.month : selectedDate.to.month}/${selectedDate.to.day < 10 ? '0' + selectedDate.to.day : selectedDate.to.day}/${
                    selectedDate.to.year
                }`;
            }
            return returnString;
        } else if (selectedDate.length > 0) {
            let returnString = [];
            for (var i = 0; i < selectedDate.length; i++) {
                if (selectedDate[i] !== null && selectedDate[i] !== '') {
                    returnString.push(
                        `${selectedDate[i].month < 10 ? '0' + selectedDate[i].month : selectedDate[i].month}/${selectedDate[i].day < 10 ? '0' + selectedDate[i].day : selectedDate[i].day}/${
                            selectedDate[i].year
                        }`
                    );
                }
            }
            return returnString.join(', ');
        } else if (props.yearRequired === undefined || props.yearRequired) {
            let returnString =
                selectedDate !== null && selectedDate !== '' && selectedDate !== undefined
                    ? `${selectedDate.month < 10 ? '0' + selectedDate.month : selectedDate.month}/${selectedDate.day < 10 ? '0' + selectedDate.day : selectedDate.day}/${selectedDate.year}`
                    : undefined;
            return returnString;
        } else {
            let returnString =
                selectedDate !== null ? `${selectedDate.month < 10 ? '0' + selectedDate.month : selectedDate.month}/${selectedDate.day < 10 ? '0' + selectedDate.day : selectedDate.day}` : undefined;
            return returnString;
        }
    };

    const getMaskPattern = () => {
        if (props.yearRequired === undefined || props.yearRequired) {
            return { date: true, delimiter: '/', datePattern: ['m', 'd', 'Y'] };
        } else {
            return { date: true, delimiter: '/', datePattern: ['m', 'd'] };
        }
    };

    const clearDate = () => {
        setDefaultDate('');
        setDateDisplay('');
        props.onChange && props.onChange(null);
    };

    const renderCustomInput = ({ ref }: any) => {
        console.log(errors, props.id);

        return (
            <div className={`text calendar-input icon-end compact ${props.required && !dateDisplay && 'required'}`}>
                <div>
                    {dateDisplay && props.clearable && (
                        <button className={`icon-end`} aria-label={'clear'} onClick={clearDate}>
                            <i className={`icon-end fas fa-times`}></i>
                        </button>
                    )}

                    <span>
                        <i className={`icon-end far fa-calendar`}></i>
                    </span>
                </div>

                {/* {
                    //Default Required
                    errors[props.id]?.type === "required" && <Validation active={true} message={"Required"} type={ValidationType.Required} />
                } */}
                <ValidationContainer errors={errors} name={props.id} value={dateDisplay} />
                {!props.isRangeSelected && !props.isMultiSelect ? (
                    <Cleave
                        options={getMaskPattern()}
                        onChange={(e: any) => onKeyUp(e)}
                        name={props.id}
                        id={props.id}
                        className={`form-control`}
                        htmlRef={register({ required: props.required })}
                        placeholder={props.placeholder || 'Select a day'}
                        value={dateDisplay}
                    />
                ) : (
                    <input
                        onChange={(e: any) => onKeyUp(e)}
                        name={props.id}
                        id={props.id}
                        required={props.required}
                        className={`form-control`}
                        ref={register({ required: props.required })}
                        placeholder={props.placeholder || 'Select a day'}
                        value={dateDisplay}
                    />
                )}
            </div>
        );
    };

    return (
        <div>
            {props.readonly && <input id={props.id} name={props.id} className={`form-control`} readOnly={props.readonly} value={dateDisplay} ref={register} />}
            {!props.readonly && (
                <DatePicker
                    value={defaultDate}
                    onChange={handleChange}
                    shouldHighlightWeekends={props.shouldHighlightWeekends || true}
                    maximumDate={props.maxDate}
                    minimumDate={props.minDate}
                    colorPrimary={getPrimaryColor(props.theme || 'Blue')}
                    colorPrimaryLight={getLighterColor(props.theme || 'Blue')}
                    disabledDays={props.disabledDays}
                    renderInput={renderCustomInput}
                    calendarPopperPosition='bottom'
                    yearRequired={props.yearRequired === undefined ? true : props.yearRequired}
                    isRangedSelected={props.isRangeSelected}
                />
            )}
        </div>
    );
};
