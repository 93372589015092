import React from 'react';
import Badge, { BadgeType } from '../Badge/Badge';

export type GroupColorOptions = 'red' | 'pink' | 'teal' | 'blue' | 'green' | 'purple';

export interface ICategoryCardProps {
    title: string;
    index?: number;
    subTitle: string;
    subTitleIcon?: string;
    subTitle2?: string;
    subTitle2Icon?: string;
    onClick?: any;
    isLoading?: boolean;
    className?: string;
    groupColorClass: GroupColorOptions;
    statusType?: BadgeType;
    status?: string;
    showStatus?: boolean;
}

export const getColorClass = (groupColorClass: GroupColorOptions, selectedIndex: number = 0): string => {
    let classes: string[] = [];
    switch (groupColorClass) {
        case 'red':
        default:
            classes.push('category-one');
            break;
        case 'pink':
            classes.push('category-two');
            break;
        case 'teal':
            classes.push('category-three');
            break;
        case 'blue':
            classes.push('category-four');
            break;
        case 'green':
            classes.push('category-five');
            break;
        case 'purple':
            classes.push('category-six');
            break;
    }

    if (selectedIndex % 5 === 0) {
        // console.log("5", selectedIndex);
        classes.push('s5');
    } else if (selectedIndex % 4 === 0) {
        // console.log("4", selectedIndex);
        classes.push('s4');
    } else if (selectedIndex % 3 === 0) {
        // console.log("3", selectedIndex);
        classes.push('s3');
    } else if (selectedIndex % 2 === 0) {
        // console.log("2", selectedIndex);
        classes.push('s2');
    } else {
        // console.log("1", selectedIndex);
        classes.push('s1');
    }

    return classes.join(' ');
};

export const CategoryCard: React.FC<ICategoryCardProps> = ({
    title,
    index,
    subTitle,
    subTitleIcon,
    subTitle2,
    subTitle2Icon,
    onClick,
    isLoading,
    className,
    groupColorClass,
    status,
    statusType,
    showStatus,
}) => {
    const getCardColor = (selectedIndex: number): string => {
        return getColorClass(groupColorClass, selectedIndex);
    };

    const getClasses = () => {
        var classes: string[] = [];

        if (isLoading) classes.push('loading');

        classes.push(className!);
        console.log(index);
        if (index === undefined) {
            let tmpIndex = Math.floor(Math.random() * 5);
            classes.push(getCardColor(tmpIndex + 1));
            console.log(tmpIndex);
        } else {
            classes.push(getCardColor(index + 1));
        }

        return classes.join(' ');
    };

    return (
        <div className={`category-card card ${getClasses()}`} onClick={onClick}>
            {showStatus && <Badge content={status} type={statusType || 'primary'} />}
            <div className='category-card-content'>
                <h4>{title}</h4>
                <div>
                    {!!subTitleIcon && <div className={`fas fa-${subTitleIcon} mr-1`}></div>}
                    {subTitle}
                </div>
                {!!subTitle2 && (
                    <div className='mt-1'>
                        {!!subTitle2Icon && <div className={`fas fa-${subTitle2Icon} mr-1`}></div>}
                        {subTitle2}
                    </div>
                )}
            </div>
        </div>
    );
};
