import React from 'react';
import ReleaseNoteItem, { ReleaseNoteItemProps } from './ReleaseNoteItem';

// Reconsider the name of this module


export interface ReleaseNotesProps {
    items: ReleaseNoteItemProps[],
    isLoading?: boolean,
    isEmpty?: boolean,
}


export default class ReleaseNotes extends React.Component<ReleaseNotesProps>
{
    public static defaultProps =
        {
            items: [{ text: "empty" }],
            compact: false,
            selectable: true,
            isEmpty: false
        };

    constructor(props: ReleaseNotesProps) {
        super(props);

        this.state =
        {
            selectedIndex: 0
        };
    }

    getClasses(): string {
        var classes: string[] = [];

        if (this.props.isLoading)
            classes.push('loading')
        return classes.join(' ');
    }

    render(): React.ReactNode {

        return (
            <React.Fragment>
                <div className={`list-group ${(this.props.isEmpty) && 'd-none'} ${this.getClasses()}`}>
                    {

                        this.props.items.map((item: ReleaseNoteItemProps, number: number) => (
                            <ReleaseNoteItem {...item} key={number} isLoading={this.props.isLoading} />
                        ))
                    }

                </div>

                <div className={this.props.isEmpty ? "empty-list" : "d-none"}>
                    <i className="fal fa-folder-open"></i>
                        No Items found
                    </div>
            </React.Fragment>
        );
    }
}