import React from 'react';

export interface AlertProps
{
    text: any,
    type:AlertType,

}
type AlertType = "success" | "info"  | "warning" | "error";

export default class Alert extends React.Component<AlertProps>
{
    public static defaultProps =
        {
            type : "info"
        };
    
        getClasses(): string
        {
            var classes: string[] = [];
    
            if (this.props.type === "success")
                classes.push('alert-success');
            else if (this.props.type === "info")
                classes.push('alert-info');   
            else if (this.props.type === "warning")
                classes.push('alert-warning');   
            else if (this.props.type === "error")
                classes.push('alert-danger');   

            return classes.join(' ');
        }
 
    render(): React.ReactNode 
    {
        return (
            <div className={`alert ${this.getClasses()}`} >{this.props.text}</div>
        );
    }
}