import React from 'react';
import { v4 as uuidv4 } from 'uuid';

import ListItem, { ListItemProps } from '../ListItem';
import AccordionItemHeader, { AccordionItemHeaderProps, AccordionItemHeaderState } from './AccordionItemHeader';
import ActionList, { ActionListType } from '../ActionList';

export enum AccordionItemState
{
    Contracted,
    Expanded
}

export interface AccordionItemProps
{
    icon: string,
    badge: number,
    title: string,
    items: ListItemProps[],
    state: AccordionItemState,
    compact: boolean,
    onClick?: (item: any) => void
    selectable?: boolean,
    isLoading?: boolean,
    isEmpty?: boolean,
}

export default class AccordionItem extends React.Component<AccordionItemProps>
{
    public static defaultProps =
        {
            icon: 'dot',
            compact: false,
            badge: 0,
            title: 'Title',
            items: [{ text: 'Item', state: '', icon: 'dot', badge: 0 }],
            state: AccordionItemState.Contracted,
            selectable: true
        };

    getClasses(): string
    {
        var classes: string[] = [];

        if (this.props.state === AccordionItemState.Expanded)
            classes.push('show');

        if (!this.props.selectable)
            classes.push('disabled');

        if (this.props.compact)
            classes.push('list-group-compact');

        return classes.join(' ');
    }

    render(): React.ReactNode
    {
        //Adding the 'A' so that the first character is not a digit, otherwise the accordion does not work
        const uniqueName = "A" + uuidv4();

        const header: AccordionItemHeaderProps =
        {
            name: uniqueName,
            icon: this.props.icon,
            label: this.props.title,
            state: this.props.state === AccordionItemState.Contracted ? AccordionItemHeaderState.Contracted : AccordionItemHeaderState.Expanded,
            badge: this.props.badge,
            compact: this.props.compact,
            onClick: this.props.onClick
        };

        return (
            <div className="accordion" id={`${uniqueName}-parent`}>
                <div className={`accordion`} id={`${uniqueName}`}>
                    <div className={`card`}>
                        <AccordionItemHeader {...header} />
                        <div id={`${uniqueName}-content`} className={`collapse  ${this.getClasses()}`} aria-labelledby={`${uniqueName}`} data-parent={`#${uniqueName}-parent`}>
                            <div className={!this.props.isEmpty ? "" : "d-none"}>
                                {
                                    this.props.isLoading
                                        ?
                                        <ActionList isLoading type={ActionListType.Normal} items={[]} />
                                        :
                                        this.props.items.map((item: ListItemProps, number: number) => (
                                            <ListItem {...item} key={number} />
                                        ))
                                }
                            </div>
                            <div className={this.props.isEmpty ? "empty-list" : "d-none"}>
                                <i className="fal fa-folder-open"></i>
                            No Items found
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}