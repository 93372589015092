import React from 'react';

export interface TableRowProps
{
    state: string, //unused still.
    items: any[]
}

export default class TableCol extends React.Component<TableRowProps>
{ 
    render(): React.ReactNode
    {
        return (
            <React.Fragment>
                {
                    this.props.items.map((item: string, number: number) =>
                    {
                        return <div key={number} style={{ order: 0 }} className="rtable-cell">{item}</div>
                    })
                }
            </React.Fragment>
        );
    }
}

