import React from 'react';

import { DropdownItem, DropdownItemProps, DropDownState } from '../Dropdown';

export interface NavItemDropDownProps
{
    icon: string,
    text: string,
    state: NavItemDropDownState,
    dropdownItems?: DropdownItemProps[]
}
 
export enum NavItemDropDownState
{
    None,
    Disabled,
    Active
}

export default class NavItemDropDown extends React.Component<NavItemDropDownProps>
{
    public static defaultProps = {
        icon: "",
        text: "Item",
        state: NavItemDropDownState.None
    };

    getClasses(): string
    {
        var classes: string[] = [];

        if (this.props.state === NavItemDropDownState.Active)
            classes.push('active');
        else if (this.props.state === NavItemDropDownState.Disabled)
            classes.push('disabled')

        return classes.join(' ');
    }

    getDropDownState(): DropDownState
    {
        if (this.props.state === NavItemDropDownState.Active)
            return DropDownState.Active;
        else if (this.props.state === NavItemDropDownState.Disabled)
            return DropDownState.Disabled;

        return DropDownState.None;
    }

    getAriaDisabledState(): boolean | undefined
    {
        if (this.props.state === NavItemDropDownState.Disabled)
            return true;

        return undefined;
    }

    render(): React.ReactNode
    {
        return (
            // MENU BAR wants the <LI> to be active 
            //NAVBARS want the <A> to be active, we need conditional thing here (it's a bootstrap issue)
            <li className={`nav-item dropdown ${this.getClasses()}`} aria-disabled={this.getAriaDisabledState()}>
                <a className={`nav-link dropdown-toggle ${this.getClasses()}`} href="/#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i className={`fal fa-${this.props.icon}`}></i>
                    {this.props.text}
                </a>
                <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink" >
                    {
                        this.props.dropdownItems && this.props.dropdownItems.map((dropdownItem: DropdownItemProps, number: number) => (
                            <DropdownItem {...dropdownItem} key={number} state={this.getDropDownState()} />
                        ))
                    }
                </div>
            </li>
        );
    }
}




