import React from 'react';
import $ from 'jquery';

import Icon, { IconType } from '../Icon';
import { AtlasTheme } from '../Template/AtlasPage';

export enum ButtonType
{
    Primary = 1,
    Secondary = 2,
    Ghost = 4,
    Small = 8,
    Large = 16,
    TextNaked = 32,
    IconNaked = 64,
    Icon = 128,
    BigIcon = 256
}

export enum ButtonState
{
    Enabled,
    Disabled
}

export interface ButtonProps
{
    icon: string,
    text: string,
    onClick: any,
    type: ButtonType,
    state: ButtonState,
    ignoreValidation?: boolean, //Only for form validation
    shouldDisplay?:any, //Only for action buttons in Advanced Table Action Column
    properties?: { [key: string]: any },
    url?: string,
    padded?: boolean,
    badgeNumber?: number,
    trigger?: any,
    theme?: AtlasTheme,
    isBusy?: boolean
    iconType?: IconType,
    title?: string
}

export default class Button extends React.Component<ButtonProps>
{
    public static defaultProps =
        {
            icon: "",
            text: "",
            onClick: () => void (0),
            type: ButtonType.Primary,
            state: ButtonState.Enabled,
            padded: true
        };

    isTypeAssigned(buttonType: ButtonType): boolean
    {
        if ((this.props.type & buttonType) === buttonType)
            return true;
        else
            return false;
    }

    getClasses(): string
    {
        var classes: string[] = [];

        if (this.isTypeAssigned(ButtonType.Primary))
        {
            if (this.isTypeAssigned(ButtonType.Ghost))
                classes.push("btn-outline-primary");
            else
                classes.push("btn-primary");
        }
        else if (this.isTypeAssigned(ButtonType.Secondary))
        {
            if (this.isTypeAssigned(ButtonType.Ghost))
                classes.push("btn-outline-secondary");
            else
                classes.push("btn-secondary");
        }


        if (this.isTypeAssigned(ButtonType.Small))
        {
            classes.push("btn-sm");
        }
        else if (this.isTypeAssigned(ButtonType.Large))
        {
            classes.push("btn-lg");
        }

        if (this.props.state === ButtonState.Disabled)
        {
            classes.push('disabled');
        }

        if (this.isTypeAssigned(ButtonType.TextNaked))
        {
            classes.push('btn-link-basic');
        }

        if (this.isTypeAssigned(ButtonType.IconNaked))
        {
            classes.push('btn-icon-basic');
        }

        if (this.isTypeAssigned(ButtonType.BigIcon))
        {
            classes.push('btn-big-icon');
        }

        if (!this.props.padded)
        {
            classes.push('no-padding');
        }

        if (this.props.isBusy) {
            classes.push('busy-indicator')
        }

        return classes.join(' ');
    }

    getAriaDisabledState(): boolean | undefined
    {
        if (this.props.state === ButtonState.Disabled)
            return true;

        return undefined;
    }

    loadTooltip(element: HTMLAnchorElement)
    {
        //@ts-ignore
        $(element).tooltip();
    }

    render(): React.ReactNode 
    {
        let tabindex = this.props.state === ButtonState.Disabled ? { tabIndex: -1 } : { tabIndex: 0 };
        var tooltipText: string;

        if (this.isTypeAssigned(ButtonType.IconNaked) || this.isTypeAssigned(ButtonType.Icon))
        {
            tooltipText = this.props.text;
        }
        else
        {
            tooltipText = "";
        }

        return (
            // This span should perhaps only be present if there is a different theme
            <span className={this.props.theme}>
                <a ref={this.loadTooltip}
                    className={`btn ${this.getClasses()}`}
                    aria-disabled={this.getAriaDisabledState()}
                    onClick={(e: any) =>
                    {
                        if(!this.props.isBusy) {
                            if (!this.props.trigger)
                                return this.props.onClick();
    
                            if (this.props.ignoreValidation)
                                return this.props.onClick();
    
                            this.props.trigger && this.props.trigger().then((result: any) =>
                            {
                                this.props.onClick(result);
                            });

                        }
                    }}
                    onKeyPress={(e: any) => this.props.onClick()}
                    role="button"
                    title={tooltipText}
                    {...tabindex}
                    {...this.props.properties} href={this.props.url}
                    aria-label={this.props.title}
                >
                    {
                        (this.props.icon.length > 0) && <Icon icon={this.props.icon} type={this.props.iconType || "light"} padded={true} />
                    }

                    {
                        (!this.isTypeAssigned(ButtonType.IconNaked) && !this.isTypeAssigned(ButtonType.Icon)) && this.props.text
                    }

                    {
                        (this.props.badgeNumber !== undefined && this.props.badgeNumber > 0) &&
                        <span className={`badge badge-pill badge-warning`}>{this.props.badgeNumber}</span>
                    }
                    {
                        (this.props.isBusy) && <div className="loader"><div className="pulsate-loading"></div></div>
                    }
                </a>
            </span>
        )
    }
}
