import React, { Fragment } from 'react';

export interface CheckboxProps
{
    label: string,
    properties?: { [key: string]: string },
    checked?: boolean,
    onClick?: any;
    name?: string;

    register?: any; // https://react-hook-form.com/api#register
    errors?: any; // https://react-hook-form.com/api#errors 
    setValue?: any; // https://react-hook-form.com/api#setValue
}

interface CheckboxState
{
    checked: boolean;
}

export default class Checkbox extends React.Component<CheckboxProps, CheckboxState>
{
    constructor(props: CheckboxProps)
    {
        super(props)

        this.state = {
            checked: this.props.checked ? this.props.checked : false
        }
    }

    componentDidUpdate(prevProps: CheckboxProps)
    {
        if (this.props.checked != prevProps.checked)
        {
            this.setState({
                checked: this.props.checked ? this.props.checked : false
            })
        }
    }

    getClasses(): string
    {
        var classes: string[] = [];

        if (this.state.checked)
            classes.push("checked");

        return classes.join(' ');
    }

    render(): React.ReactNode 
    {
        return (
            <div className={`form-check ${this.getClasses()}`}>
                <input ref={this.props.register({ type: 'checkbox' })} onClick={() => { this.setState({ checked: !this.state.checked }); this.props.onClick(!this.state.checked) }} defaultChecked={this.state.checked} className="form-check-input" type="checkbox" name={this.props.name} id={this.props.name} {...this.props.properties} />
                <label className="form-check-label" htmlFor={this.props.name}><b>{this.props.label}</b></label>
            </div>
        );
    }
}