import React, { Fragment } from 'react';
import Icon, { IconType } from '../Icon';

export interface ContactCardProps
{
    name: string,
    affiliation?: string,
    department?: string,
    company?:string,
    address?: string,
    telephone?: string,
    email?:string,
    isLoading?: boolean,
    profilePicUrl? : string,
    profilePicIcon?: string,
    isPerson?:boolean,
    pictureOnly?:boolean,
    isEmpty?:boolean
}
 
export default class ContactCard extends React.Component<ContactCardProps>
{

    public static defaultProps = {
        isPerson:false,
        isEmpty: false
    };

    render(): React.ReactNode 
    {
        if (!this.props.pictureOnly)
        {
            return (
                <div className={this.props.isLoading ? "card contact-card loading m-3" : "card contact-card m-3"}>
                    <div className={this.props.isPerson && !this.props.isEmpty ? "profile-picture" : "profile-picture notPerson"} style={{backgroundImage: "url(" + this.props.profilePicUrl + ")"}}></div>
                    <div className={this.props.isPerson && !this.props.isEmpty ? "contact-sheet p-3" : "contact-sheet p-3 notPerson"}>
                        {!this.props.isEmpty ? 
                        <Fragment>
                            {this.props.profilePicIcon 
                                ?   <>
                                    <div className="profile d-flex align-items-center">
                                        <div className="profile-icon">
                                            <img alt={"profile"}  src={this.props.profilePicIcon} className={"img-object-large-fit"} />
                                        </div>
                                        <div className="profile-name">
                                            <h5>{this.props.name}</h5>
                                            <p>{this.props.department}</p>
                                        </div>
                                    </div>
                                    <div className="profile-info">
                                        {this.props.company && <div className="">{this.props.company}</div>}
                                        {this.props.affiliation && <div><Icon icon="people-arrows" type={"light"} padded={true} /> {this.props.affiliation}</div>}
                                        {this.props.address && <div><Icon icon="building" type={"light"} padded={true} /> {this.props.address}</div>}
                                        {this.props.telephone && <div><Icon icon="phone" type={"light"} padded={true} /> {this.props.telephone}</div>}
                                        {this.props.email && <div><a href={`mailto:${this.props.email}`}><Icon icon="envelope" type={"light"} padded={true} /> {this.props.email}</a></div>}
                                    </div>
                                    </>
                                : <>
                                    <div><h5>{this.props.name}</h5></div>
                                    {this.props.department && <div>{this.props.department}</div>}
                                    {this.props.company && <div>{this.props.company}</div>}
                                    {this.props.affiliation && <div><Icon icon="people-arrows" type={"light"} padded={true} /> {this.props.affiliation}</div>}
                                    {this.props.address && <div><Icon icon="building" type={"light"} padded={true} /> {this.props.address}</div>}
                                    {this.props.telephone && <div><Icon icon="phone" type={"light"} padded={true} /> {this.props.telephone}</div>}
                                    {this.props.email && <div><a href={`mailto:${this.props.email}`}><Icon icon="envelope" type={"light"} padded={true} /> {this.props.email}</a></div>}
                                </>
                            }
                        </Fragment> :
                        <div className="contact-card-empty">
                            <i className="fal fa-user-alt-slash"></i>
                            No person found
                        </div>
                        }
                        
                    </div>
                </div>
            );
        }
        else {
            return(
                <div className={this.props.isLoading ? "card picture-only contact-card loading m-3" : "card picture-only contact-card m-3"}>
                    <div className={this.props.isPerson ? "profile-picture" : "profile-picture notPerson"} style={{backgroundImage: "url(" + this.props.profilePicUrl + ")"}}></div>
                    
                </div>

            );
        
        }
        
    }
}