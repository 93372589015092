import React from 'react';
import { Validation, ValidationType } from '.';
import lodashGet from 'lodash.get';

interface Props {
    value: any;
    errors: any;
    name: string;
    validation?: any;
    keyword?: string;
}

export const ValidationContainer: React.FC<Props> = ({ value, errors, validation, name, keyword }) => {
    const getError = (name: string = '') => {
        // let items = name.split(".");
        // let error_value = errors || {};
        // for (let item of items) {
        //     error_value = error_value && error_value[item] ? error_value[item] : error_value;
        // }
        // if (!Object.keys(error_value).length || !!value) return null;
        // return error_value;
        return lodashGet(errors, name);
    };

    return (
        <>
            {
                //Validation prop defined
                validation && !!getError(name) && <Validation {...validation} />
            }
            {
                //Default Required
                !validation && !!getError(name) && getError(name)?.type === 'required' && <Validation active={true} message={'Required'} type={ValidationType.Required} />
            }
            {
                //Default Max Length
                !validation && !!getError(name) && getError(name)?.type === 'maxLength' && <Validation active={true} message={'MaxLength'} type={ValidationType.Required} />
            }
            {
                //Default Min Length
                !validation && !!getError(name) && getError(name)?.type === 'minLength' && <Validation active={true} message={'MinLength'} type={ValidationType.Required} />
            }
            {
                //Default Max
                !validation && !!getError(name) && getError(name)?.type === 'max' && <Validation active={true} message={'Max'} type={ValidationType.Required} />
            }
            {
                //Default Min
                !validation && !!getError(name) && getError(name)?.type === 'min' && <Validation active={true} message={'Min'} type={ValidationType.Required} />
            }
            {
                //Default Pattern
                keyword && !validation && !!getError(name) && <Validation active={true} message={getError(name)?.message} type={ValidationType.Required} />
            }
        </>
    );
};
