import React from "react";
import ReactDOM from "react-dom"
import $ from 'jquery';

import { Button, ButtonType } from "..";

export interface HelpIconProps
{
    title: string;
    description: string;
    embedded: boolean;
}

export default class HelpIcon extends React.Component<HelpIconProps>
{
    loadPopOver(element: Button | null, self: HelpIcon)
    {
        var node = ReactDOM.findDOMNode(element)

        if (node != null)
        {
            //@ts-ignore
            $(node).tooltip('dispose');

            //@ts-ignore
            $(node).popover(
                {
                    content: self.props.description,
                    title: self.props.title,
                    trigger: 'focus'
                });
        }
    }
 
    render(): React.ReactNode
    {
        return (
            <span className="help-icon">
                <Button type={ButtonType.IconNaked} text="Help" icon={"question-circle"} ref={element => this.loadPopOver(element, this) } />
            </span>
        );
    }
}
