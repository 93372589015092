import React from "react";
import Text, { TextType } from "../components/Text";
import Icon, { IconProps, IconType } from "./Icon";

//https://www.digitalocean.com/community/tutorials/how-to-build-a-custom-toggle-switch-with-react#step-4-%E2%80%94-building-the-sample-app

interface ToggleState {
	choice: ToggleChoice;
}

interface ToggleProps {
	choice: ToggleChoice;
	name?: string;
	onClick?: any;
	static?: boolean;
	onStateChanged?: any;
	showBothOptions?: boolean;
	inverse?: boolean; //swap positive and negative colors
	inverseLabels?: boolean;
	positiveLabel?: string; // only for options that show labels
	negativeLabel?: string; // only for options that show labels
	neutralLabel?: string;
	disabled?: boolean;
}

export type ToggleChoice = "NotSelected" | "Positive" | "Negative" | "Neutral";

export default class Toggle extends React.Component<ToggleProps, ToggleState> {
	constructor(props: ToggleProps) {
		super(props);

		if (!this.props.showBothOptions) {
			this.state = {
				choice: this.props.choice === "NotSelected" ? "Negative" : this.props.choice,
			};
		} else {
			this.state = {
				choice: this.props.choice,
			};
		}
	}

	componentDidUpdate(prevProps: ToggleProps) {
		if (this.props.choice != prevProps.choice) {
			this.setState({
				choice: this.props.choice === "NotSelected" ? "Negative" : this.props.choice,
			});
		}
	}

	toggleSwitch = (evt: any, state: ToggleChoice) => {
		if (this.props.static) return;

		evt.persist();
		evt.preventDefault();

		const { onClick, onStateChanged } = this.props;

		this.setState({ choice: state }, () => {
			const state = this.state;

			// Augument the event object with SWITCH_STATE
			const switchEvent = Object.assign(evt, { SWITCH_STATE: state });

			// Execute the callback functions
			onClick && onClick(switchEvent);
			onStateChanged && onStateChanged(state);
		});
	};

	getPositiveToggleIcon = (choice: boolean) => {
		if (this.props.inverseLabels) {
			choice = !choice;
		}

		if (choice) {
			return this.props.positiveLabel;
		} else {
			return this.props.negativeLabel;
		}
	};

	toggleSwitchEnter = (evt: any, state: ToggleChoice) => {
		if (this.props.static) return;

		if (evt.keyCode === 13) {
			evt.persist();
			evt.preventDefault();

			const { onClick, onStateChanged } = this.props;

			this.setState({ choice: state }, () => {
				const state = this.state;

				// Augument the event object with SWITCH_STATE
				const switchEvent = Object.assign(evt, { SWITCH_STATE: state });

				// Execute the callback functions
				onClick && onClick(switchEvent);
				onStateChanged && onStateChanged(state);
			});
		}
	};

	render() {
		const { choice } = this.state;

		// Isolate special props and store the remaining as restProps
		const { choice: _choice, onClick, onStateChanged, ...restProps } = this.props;

		const getClasses = (): string => {
			var classes: string[] = [];

			classes.push("switch-toggle");

			if (this.props.inverse) classes.push("inverse");

			if (this.props.disabled) classes.push("disabled");

			classes.push(`switch-toggle--${choice === "Positive" ? "on" : "off"}`);

			return classes.join(" ");
		};

		if (!this.props.showBothOptions)
			return (
				<div className={`switch switch--default ${getClasses()}`} onClick={(e: any) => this.toggleSwitch(e, this.state.choice === "Positive" ? "Negative" : "Positive")}>
					<input type="hidden" name={this.props.name} value={this.state.choice.toString()} />
					<div
						onKeyDown={(e: any) => this.toggleSwitchEnter(e, this.state.choice === "Positive" ? "Negative" : "Positive")}
						tabIndex={0}
						role="switch"
						aria-checked={this.state.choice === "Positive" ? true : false}
						className={getClasses()}></div>
					{this.state.choice === "Negative" && this.props.negativeLabel && <Text color="red" type={TextType.Body} content={this.props.negativeLabel} />}
					{this.state.choice === "Positive" && this.props.positiveLabel && <Text color="green" type={TextType.Body} content={this.props.positiveLabel} />}
				</div>
			);

		if (this.props.showBothOptions)
			return (
				<div className={`switch-options ${getClasses()}`}>
					<input type="hidden" name={this.props.name} value={this.state.choice.toString()} />
					{choice === "Positive" ? (
						<a className={`positive`} tabIndex={0} role="button" aria-pressed={true}>
							{this.getPositiveToggleIcon(true)}
						</a>
					) : (
						<a
							className={``}
							tabIndex={0}
							role="button"
							aria-pressed={false}
							onKeyDown={(e: any) => {
								this.toggleSwitchEnter(e, "Positive");
							}}
							onClick={(e) => this.toggleSwitch(e, "Positive")}>
							{this.getPositiveToggleIcon(true)}
						</a>
					)}
					{this.props.neutralLabel && (
						<>
							{choice === "Neutral" ? (
								<a className={`positive`} tabIndex={0} role="button" aria-pressed={true}>
									{this.props.neutralLabel}
								</a>
							) : (
								<a
									className={``}
									role="button"
									aria-pressed={false}
									tabIndex={0}
									onKeyDown={(e: any) => {
										this.toggleSwitchEnter(e, "Neutral");
									}}
									onClick={(e) => this.toggleSwitch(e, "Neutral")}>
									{this.props.neutralLabel}
								</a>
							)}
						</>
					)}
					{choice === "Negative" ? (
						<a tabIndex={0} className={`negative`} role="button" aria-pressed={true}>
							{this.getPositiveToggleIcon(false)}
						</a>
					) : (
						<a
							tabIndex={0}
							className={``}
							role="button"
							aria-pressed={false}
							onKeyDown={(e: any) => {
								this.toggleSwitchEnter(e, "Negative");
							}}
							onClick={(e) => this.toggleSwitch(e, "Negative")}>
							{this.getPositiveToggleIcon(false)}
						</a>
					)}
				</div>
			);
	}
}
