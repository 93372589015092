import React, { Fragment } from "react";
import "bootstrap/dist/js/bootstrap.js";
import "../../css/boston-bounce-2.min.css";
import { NavBar, NavBarTypes, ResponsiveTypes, NavItemProps } from "../Nav";
import DarkMode from "../DarkMode";
import GoogleAnalytics from "../GoogleAnalytics";
import Div100vh from "react-div-100vh";

export interface AtlasPageProps {
	name: string;
	subtitle?: string;
	navigation?: NavBar | any;
	utilityNavigation?: NavItemProps[];
	content: any;
	environment?: ProductionEnvironment;
	theme?: AtlasTheme;
	profile?: any;
	customLogo?: any;
	onLogoClick?: (event: React.MouseEvent) => void;
	gaId: string;
	inverseHeading?: boolean;
	utilMaxWidth?: number;
	utilMinWidth?: number;
}

export type AtlasTheme = "default" | "medical" | "medical-dark" | "max-contrast" | "ruby";

export type ProductionEnvironment = "production" | "staging" | "dev" | "admin" | "superuser" | "helpdesk" | "online" | "offline";

export interface AtlasFeedbackProps {
	name: string;
	icon: string;
	onSubmit: any;
	formName?: string;
	formEmail?: string;
	formComment?: string;
}

export default class AtlasPage extends React.Component<AtlasPageProps> {
	constructor(props: AtlasPageProps) {
		super(props);
	}

	componentDidMount() {
		if (this.props.navigation && this.props.navigation.props && this.props.navigation.props.tabs && this.props.navigation.props.tabs.length === 1)
			document.getElementsByClassName("atlas-theme")[0].classList.add("single-item");
	}

	public static defaultProps = {
		name: "New Application",
		theme: "default",
	};

	getClasses(): string {
		var classes: string[] = [];

		if (this.props.theme === "default") {
			classes.push("mit-bounce");
		}
		if (this.props.theme === "medical") {
			classes.push("medical");
		}
		if (this.props.theme === "medical-dark") {
			classes.push("medical-dark");
		}

		if (this.props.theme === "max-contrast") {
			classes.push("max-contrast");
		}
		if (this.props.inverseHeading) {
			// this is mostly for content type sites with a heroshot area where we do not know what it will be
			classes.push("text-white");
		}

		return classes.join(" ");
	}

	render(): React.ReactNode {
		return (
			<Fragment>
				<GoogleAnalytics gaId={this.props.gaId} />
				<div className={` ${this.getClasses()}`}>
					<div className="profile-hover">
						{this.props.utilityNavigation && (
							<NavBar
								maxWidth={this.props.utilMaxWidth}
								minWidth={this.props.utilMinWidth}
								responsiveType={ResponsiveTypes.CollapseAll}
								type={NavBarTypes.IconOnly}
								tabs={this.props.utilityNavigation}
							/>
						)}
						{this.props.profile}
					</div>
				</div>

				<Div100vh>
					<div className={`atlas-theme ${this.getClasses()}`}>
						<span className="mobile-handle">
							<i className="fas fa-chevron-down"></i>
						</span>
						<div className="appName">
							{this.props.name}
							<div className="appSubName">{this.props.subtitle}</div>
						</div>

						<div className={`sidebar`}>
							<div className="logo">
								<div className="mb-3">
									{this.props.customLogo ? (
										this.props.customLogo
									) : (
										<a onClick={(e: React.MouseEvent) => this.props.onLogoClick && this.props.onLogoClick(e)}>
											<svg width="49px" height="54px" viewBox="0 0 49 54" version="1.1">
												<title>Atlas Logo</title>
												<g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
													<g id="Primary-Menu" transform="translate(-22.000000, -21.000000)">
														<g id="Logo" transform="translate(22.000000, 21.000000)">
															<path
																d="M28.3506045,0 L49,54 L32.1588946,54 L29.3238342,46.6401869 L15.275475,46.6401869 L12.3557858,54 L8.50518135,54 L0,40.5 L15.275475,0 L28.3506045,0 Z M22.1727116,23.4672897 L18.5759931,33.3084112 L25.8963731,33.3084112 L22.1727116,23.4672897 Z"
																id="Combined-Shape"
																fill="#FFFFFF"></path>
															<polygon id="Path-4" fill="#DE4141" points="0 40.5 11 40 8.5 54"></polygon>
														</g>
													</g>
												</g>
											</svg>
										</a>
									)}
								</div>
								{this.props.environment === "dev" && <div className="badge badge-info">Dev</div>}
								{this.props.environment === "staging" && <div className="badge badge-info">Staging</div>}
								{this.props.environment === "admin" && <div className="badge badge-info">Admin</div>}
								{this.props.environment === "superuser" && <div className="badge badge-info">Super User</div>}
								{this.props.environment === "helpdesk" && <div className="badge badge-info">Help Desk</div>}
								{this.props.environment === "online" && <div className="badge badge-success">Online</div>}
								{this.props.environment === "offline" && <div className="badge badge-danger">Online</div>}
							</div>
							{this.props.navigation && (
								<div role="navigation" aria-label="primary">
									{this.props.navigation}
								</div>
							)}
						</div>

						<section className="mainbar" role="main" aria-label="mainpagearea">
							{this.props.content}
						</section>
					</div>
				</Div100vh>
			</Fragment>
		);
	}
}
