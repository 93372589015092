import React from "react";
import { Button, ButtonProps } from "./Button";
import ProgressBar, { ProgressBarProps } from "./ProgressBar";
import $ from "jquery";
import { v4 as uuidv4 } from "uuid";
import Badge, { BadgeProps } from "./Badge/Badge";

export enum ListItemState {
	None,
	Disabled,
	Active,
	RecentlyAdded,
}

export interface ListItemProps {
	icon?: string;
	text: string;
	state?: ListItemState;
	badge?: BadgeProps;
	className?: string;
	badgeDot?: boolean;
	disabledTitle?: string;
	filter?: string;
	template?: any;
	itemIndex?: number;
	beforeSecondary?: any;
	secondaryText?: any;
	tertiaryText?: any;
	quaternaryText?: any;
	actions?: ButtonProps[];
	progress?: ProgressBarProps;
	onClick?: (event: React.MouseEvent, item: ListItemProps) => void;
	indented?: number;
	customClass?: string;
	loading?: boolean;
	singleLine?: boolean;
}

//need an action list object passed through as well
//need more logic to ensure we can combine many of these
export default class ListItem extends React.Component<ListItemProps> {
	private id = uuidv4();

	componentDidMount() {
		if (this.props.state === ListItemState.Disabled) {
			//@ts-ignore
			$("#" + this.id).tooltip({
				placement: "top",
				title: this.props.disabledTitle ? this.props.disabledTitle : "",
				trigger: "hover",
			});
		}
	}

	public static defaultProps = {
		icon: "",
		text: "Item",
		state: ListItemState.None,
		badge: "",
		badgeDot: false,
		itemIndex: 0,
		singleLine: false,
	};

	getClasses(): string {
		var classes: string[] = [];

		if (this.props.state === ListItemState.Active) classes.push("active");
		else if (this.props.state === ListItemState.Disabled) classes.push("disabled");

		if (this.props.badgeDot) classes.push("badgedot");
		if (this.props.state === ListItemState.RecentlyAdded) classes.push("recently-added");

		if (this.props.icon !== "") classes.push("hasIcon");

		if (this.props.indented === 1) classes.push("indent-1");
		if (this.props.indented === 2) classes.push("indent-2");
		if (this.props.indented === 3) classes.push("indent-3");
		if (this.props.indented === 4) classes.push("indent-4");

		if (this.props.loading) classes.push("loading");

		if (this.props.customClass) classes.push(this.props.customClass);

		return classes.join(" ");
	}

	getAriaDisabledState(): boolean | undefined {
		if (this.props.state === ListItemState.Disabled) return true;

		return undefined;
	}

	getClassName(): string {
		let classNames = "list-item";

		if (this.props.className) classNames = `${classNames} ${this.props.className}`;

		return classNames;
	}

	render(): React.ReactNode {
		if (!this.props.template) {
			return (
				<div className={this.getClassName()}>
					{this.props.actions !== undefined && (
						<div className="list-actions">
							{this.props.actions.map((item: ButtonProps, number: number) => (
								<Button {...item} key={number} />
							))}
						</div>
					)}
					<a
						id={this.id}
						className={`list-group-item flex-column align-items-start ${this.getClasses()}`}
						tabIndex={0}
						aria-disabled={this.getAriaDisabledState()}
						onKeyPress={(e: any) => this.props.onClick && this.props.onClick(e, this.props)}
						onClick={(e: React.MouseEvent) => this.props.onClick && this.props.onClick(e, this.props)}>
						<div className="d-flex w-100 justify-content-between">
							<p className="primary">
								{this.props.icon !== "" && <i className={`fal fa-${this.props.icon}`}></i>}

								{this.props.text !== undefined && <span dangerouslySetInnerHTML={{ __html: this.props.text }}></span>}
							</p>
							
							{this.props.badge && <div className="badge-container"><Badge {...this.props.badge} /></div>}
							{this.props.badgeDot && (
								<span className={`badge badge-pill float-right badge-warning`}>
									<div className={`p-1`}></div>
								</span>
							)}
							{!(!this.props.beforeSecondary && !this.props.secondaryText) &&
								<div className="d-flex">
									{this.props.beforeSecondary}
									<p className="text-right">{this.props.secondaryText}</p>
								</div>
							}
							{!(!this.props.secondaryText) && !this.props.secondaryText && <p className="text-right">{this.props.secondaryText}</p>}
							{this.props.singleLine &&
								<>
									{this.props.tertiaryText && <p className="additional">{this.props.tertiaryText}</p>}
									{this.props.quaternaryText && <p className="additional text-right">{this.props.quaternaryText}</p>}
								</>
							}
						</div>
						{!this.props.singleLine &&
							<div className="d-flex w-100 justify-content-between">
								{/* ensure the padding is not present if icon is not here */}
								<p className="additional">{this.props.tertiaryText}</p>

								<p className="additional text-right">{this.props.quaternaryText}</p>
							</div>
						}
						{this.props.progress && <ProgressBar {...this.props.progress} />}
					</a>
				</div>
			);
		} else {
			return <React.Fragment>{this.props.template(this.props)}</React.Fragment>;
		}
	}
}
