import React from 'react';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { Button, ButtonType, ButtonState } from './Button';
import { FileUpload } from './FileUpload/FileUpload';
import { HotTable } from '@handsontable/react';
import 'handsontable/dist/handsontable.full.css';
import Handsontable from 'handsontable';
import Drawer, { DrawerType, DrawerLayout, DrawerSize } from './Drawer';
import Text, { TextType } from './Text';
import $ from 'jquery';
import Icon, { IconProps } from './Icon';
import { RadioGroupOption } from './Form/RadioGroup';
import { IValidationPattern } from './Form/FormValidation';
import { AtlasTheme } from '../components/Template';
import moment from 'moment';

export interface BulkUploadProps {
    formFields?: Record<string, BulkUploadField | any>;
    autoSave?: boolean;
    onImport?: (data: any) => Promise<void>;
    onRowValidationSuccess?: (data: any) => Promise<void>;
    saveFormat?: 'aoa' | 'blob' | 'file';
    themedColor?: AtlasTheme;
    showTemplate?: boolean;
    drawerSize?: DrawerSize;
}

export interface BulkUploadField {
    max?: number;
    maxLength?: number;
    min?: number;
    minLength?: number;
    pattern?: IValidationPattern;
    required?: boolean;
    type?: 'checkbox' | 'radioGroup' | 'calendar' | 'time';
    format?: string;
    groupOptions?: Array<RadioGroupOption>;
}

interface BulkUploadState {
    isFileLoaded: boolean;
    isSyncing: boolean;
    importData: any[];
    columnSettings: any[];
    colHeaders: any[];
    numberOfRows: number;
    isSuccess: boolean;
}

export const iconData: IconProps = {
    type: 'light',
    icon: 'bell',
    padded: false,
};

export default class BulkUpload extends React.Component<BulkUploadProps, BulkUploadState> {
    hotTableComponent: any;
    syncElement: any;
    rowNumberElement: any;

    constructor(props: BulkUploadProps) {
        super(props);

        this.state = {
            isFileLoaded: false,
            importData: [],
            columnSettings: [],
            colHeaders: [],
            numberOfRows: 0,
            isSyncing: false,
            isSuccess: false,
        };
    }

    handleSyncRef = (ref: any) => {
        this.syncElement = ref;
        $(this.syncElement).hide();
    };

    handleRowNumberRef = (ref: any) => {
        this.rowNumberElement = ref;
    };

    exportToCSV = () => {
        let sheet = [];

        for (var key in this.props.formFields) {
            sheet.push(key);
        }

        const fileName = 'template';
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';

        const ws = XLSX.utils.aoa_to_sheet([sheet]);
        const wb = { Sheets: { data: ws }, SheetNames: ['data'] };

        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    };

    importCSV = (file: any) => {
        const reader = new FileReader();

        reader.onabort = () => console.log('file reading was aborted');
        reader.onerror = () => console.log('file reading has failed');
        reader.onload = () => {
            // Do whatever you want with the file contents
            //@ts-ignore
            var data = new Uint8Array(reader.result);
            var workbook = XLSX.read(data, { type: 'array', cellDates: true });
            var sheet: XLSX.WorkSheet = {};

            for (var idx in workbook.SheetNames) {
                var sheetName = workbook.SheetNames[idx];
                sheet = workbook.Sheets[sheetName];

                break;
            }

            var importData = XLSX.utils.sheet_to_json(sheet, { defval: null });

            for (var idx in importData) {
                var colData: any = importData[idx];

                for (var colIdx in colData) {
                    if (colData[colIdx] && typeof colData[colIdx].getMonth === 'function') {
                        let date = colData[colIdx];
                        let displayDate = date.getFullYear() === 1899 ? moment(date).format('LT') : moment(date).format('YYYY-MM-DD');

                        colData[colIdx] = displayDate;
                    }
                }
            }

            var cols = [];
            var colHeaders = [];

            for (var key in this.props.formFields) {
                var itm = this.props.formFields[key];

                colHeaders.push(key);

                let col = {
                    data: key,
                    validator: ((itm) => {
                        //@ts-ignore
                        return (value, callback) => this.validator(itm, value, callback);
                    })(itm),
                };

                if (itm.type == 'checkbox')
                    //@ts-ignore
                    col.type = 'checkbox';
                else if (itm.type == 'radioGroup') {
                    //@ts-ignore
                    col.type = 'dropdown';
                    //@ts-ignore
                    col.source = itm.groupOptions.map((itm: any) => itm.value);
                } else if (itm.type == 'calendar') {
                    //@ts-ignore
                    col.type = 'date';
                    //@ts-ignore
                    col.correctFormat = true;
                    //@ts-ignore
                    col.dateFormat = 'YYYY-MM-DD';
                } else if (itm.type == 'time') {
                    //@ts-ignore
                    col.type = 'time';
                    //@ts-ignore
                    col.correctFormat = true;
                }

                cols.push(col);
            }

            this.setState({
                importData: importData,
                columnSettings: cols,
                colHeaders: colHeaders,
                isFileLoaded: true,
                numberOfRows: importData.length,
            });
        };

        reader.readAsArrayBuffer(file);
    };

    importData = () => {
        let data = this.hotTableComponent.getData();

        // access to exportFile plugin instance
        let exportPlugin = this.hotTableComponent.getPlugin('exportFile');

        this.setState({
            isSuccess: true,
            isFileLoaded: false,
        });

        if (this.props.onImport) {
            if (this.props.saveFormat && this.props.saveFormat === 'file') {
                // export as a blob object
                var blob = exportPlugin.exportAsBlob('csv');

                let file = new File([blob], 'upload.csv');

                this.props.onImport(file).then(() => {});
            } else {
                this.props.onImport(data).then(() => {});
            }
        }
    };

    afterChange = (changes: any, source: string) => {
        if (source === 'loadData') {
            return; //don't save this change
        }

        if (!this.props.autoSave) {
            return;
        }

        for (var idx in changes) {
            let change = changes[idx];

            let row = change[0];

            this.hotTableComponent.validateRows([row], (valid: boolean) => {
                if (valid) {
                    var self = this;
                    $(this.syncElement).show();
                    let data = this.hotTableComponent.getDataAtRow(row);
                    this.props.onRowValidationSuccess &&
                        this.props.onRowValidationSuccess(data).then(() => {
                            $(self.syncElement).hide();
                        });

                    //$(this.rowNumberElement).html(parseInt($(this.rowNumberElement).html()) - 1);
                }
            });
        }
    };

    handleRef = (hotTableComponent: any) => {
        if (hotTableComponent) {
            hotTableComponent.hotInstance.validateCells();
            this.hotTableComponent = hotTableComponent.hotInstance;
        }
    };

    validator = (field: any, value: any, callback: any): void => {
        if (field.pattern) {
            var regex = new RegExp(field.pattern.value);

            if (regex.test(value)) {
                callback(true);
            } else {
                if (!field.required && (value == undefined || value == null || value.length == 0)) callback(true);
                else callback(false);
            }

            return;
        }

        if (field.required) {
            if (value == undefined || value == null || value.length == 0) {
                callback(false);
            } else {
                callback(true);
            }

            return;
        }

        callback(true);
    };

    cells = (row: any, col: any): Handsontable.CellMeta => {
        const cellProperties: Handsontable.CellMeta = {};

        if (row === 0) {
            cellProperties.className = 'header';
            cellProperties.readOnly = true;
        }

        return cellProperties;
    };

    render(): React.ReactNode {
        return (
            <>
                <div className='bulk-upload'>
                    {this.props.showTemplate && (
                        <div className='template-button'>
                            <Button icon='file-excel' text='Download Template' onClick={() => this.exportToCSV()} type={ButtonType.TextNaked} />
                        </div>
                    )}
                    <div className='mb-2'></div>
                    <FileUpload acceptedMimeTypes={['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']} onConfirm={(file: File) => this.importCSV(file)} />
                    <div className='mb-4'></div>
                </div>

                <Drawer
                    type={DrawerType.Right}
                    layout={DrawerLayout.Clean}
                    size={this.props.drawerSize}
                    themedColor={this.props.themedColor}
                    footer={[
                        { icon: '', text: 'Save', type: ButtonType.Primary, onClick: () => this.importData(), state: ButtonState.Enabled },

                        { icon: '', text: 'Cancel', type: ButtonType.Primary | ButtonType.Ghost, onClick: () => this.setState({ isFileLoaded: false }), state: ButtonState.Enabled },
                    ]}
                    header={<Text type={TextType.Heading3} content={'Bulk upload'} />}
                    contents={
                        <>
                            <p>Please resolve any validation errors</p>

                            <div className='hottable-header-info mb-1'>
                                <div>{this.state.numberOfRows} Row(s)</div>
                            </div>
                            <HotTable
                                ref={this.handleRef}
                                data={this.state.importData}
                                height={200}
                                columns={this.state.columnSettings}
                                colHeaders={this.state.colHeaders}
                                width={'100%'}
                                licenseKey={'non-commercial-and-evaluation'}
                                afterChange={this.afterChange}
                            />
                            <div className='syncing-icon' ref={this.handleSyncRef}>
                                <Icon icon='sync-alt' type={'solid'} padded={true} />
                                {'Syncing'}
                            </div>
                        </>
                    }
                    show={this.state.isFileLoaded}
                />
            </>
        );
    }
}
