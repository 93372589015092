import React from 'react';

import ListItem, { ListItemProps, ListItemState } from './ListItem';
import { Textbox, TextboxProps, TextboxType } from './Textbox';

export interface SearchListProps {
    searchOptions: TextboxProps;
    items: ListItemProps[];
    compact?: boolean;
    selectable?: boolean;
    height: number, //this is to be represented as vertical height in the CSS
    isLoading?: boolean,
    isEmpty: boolean,
    onChange?: (item: any) => void
    onSearch?: (keyword: string) => void
    isLoadingList?: boolean
}

interface FilteredListState {
    keyword: string,
    data: ListItemProps[],
    selectedIndex: number
}

export default class FilteredList extends React.Component<SearchListProps, FilteredListState>
{
    public static defaultProps =
        {
            compact: false,
            selectable: true,
            isEmpty: false
        };

    constructor(props: SearchListProps) {
        super(props);

        let activeItem = -1;

        this.props.items.map((itm: ListItemProps, index: number) => {
            if (itm.state == ListItemState.Active)
                activeItem = index;

            itm.itemIndex = index
        })

        this.state =
        {
            keyword: '',
            data: this.props.items,
            selectedIndex: activeItem
        };
    }

    componentDidUpdate(prevProps: SearchListProps) {
        if (this.props.items != prevProps.items) {
            this.setState
                ({
                    data: this.props.items,
                    selectedIndex: this.getActiveIndex()
                });
        }
    }

    getActiveIndex() {

        let activeIndex = -1
        if (this.props.items) {
            this.props.items.map((itm: ListItemProps, index: number) => {
                if (itm.state == ListItemState.Active) {
                    activeIndex = index;
                }
            });
        }
        console.log('getActiveIndex', activeIndex)
        return activeIndex;

    }

    onKeyUp(element: HTMLInputElement & EventTarget): void {
        var keyword = element.value;

        this.setState
            ({
                keyword: keyword

            });

        this.props.onSearch && this.props.onSearch(keyword)
    }

    getState(item: ListItemProps): ListItemState | undefined {
        if (!this.props.selectable)
            return ListItemState.None;

        if (this.state.keyword)
            return ListItemState.None;

        if (item.state == ListItemState.Disabled)
            return ListItemState.Disabled

        return this.state.selectedIndex === item.itemIndex ? ListItemState.Active : ListItemState.None;
    }

    onClick(item: ListItemProps): boolean {
        if (this.props.selectable) {
            this.setState
                ({
                    data: this.state.data,
                    selectedIndex: item.itemIndex ? item.itemIndex : 0
                });
        }

        this.props.onChange && this.props.onChange(item)
        return true;
    }

    onTextboxEndIconClick(): void {
        this.setState
            ({
                keyword: ''
            });

        this.props.onSearch && this.props.onSearch('')
    }


    getHeight(): any {
        if (this.props.height > 0)
            return { height: `${this.props.height}vh` };
        else
            return null;
    }

    render(): React.ReactNode {
        return (
            //Specified count
            <div className={this.props.isLoading ? "search-list loading" : "search-list"}>
                <div className="px-4 pt-4">
                    {/* <div className="load-indicator">
                        <div className="pulsate-loading"></div>
                        <div className="load-text">Loading list</div>
                    </div> */}
                    <Textbox
                        onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => this.onKeyUp(e.currentTarget)}
                        onEndIconClick={(e: React.MouseEvent) => this.onTextboxEndIconClick()}
                        {...this.props.searchOptions}
                        properties={{ "aria-label": "Search", "autoComplete": "off" }}
                        type={TextboxType.IconStart | TextboxType.IconEnd}
                        clearable
                         />
                </div>


                <div className={`list-group px-4 ${(this.props.compact) && 'list-group-compact'} ${(this.props.isEmpty) && 'd-none'} text`} style={this.getHeight()}>
                    <li className="list-group-item skeleton">&nbsp;</li>
                    <li className="list-group-item skeleton">&nbsp;</li>
                    <li className="list-group-item skeleton">&nbsp;</li>
                    {
                        this.state.data.map((item: ListItemProps, number: number) => {
                            return <ListItem {...item} state={this.getState(item)} filter={this.state.keyword} key={number} onClick={(event: React.MouseEvent) => this.onClick(item) && (item.onClick && item.onClick(event, item))} />
                        })
                    }
                </div>
                <div className={this.props.isEmpty ? "empty-list" : "d-none"}>
                    <i className="fal fa-folder-open"></i>
                    No Items found
                </div>

            </div>
        )
    }
}