import React, { useEffect, useState } from 'react';
import { AutoComplete } from '../AutoComplete';

// types?: 'country' | 'region' | 'postcode' | 'district' | 'place' | 'locality' | 'neighborhood' | 'address' | 'poi'

interface Props {
    accessToken: string;
    name: string;
    icon?: string;
    value?: any;
    required?: boolean;
    errors?: any;
    limit?: number;
    country?: string;
    types?: String;
    useCurrentLocation?: boolean;
    proximity?: Coordinates;
    boxed?: BBOX;
    onChange?: (v: any) => void;
}

interface Coordinates {
    lng: string;
    lat: string;
}

interface BBOX {
    first_bbox: Coordinates;
    second_bbox: Coordinates;
}

export const LocationSearch: React.FC<Props> = ({ name, icon, required, value, errors, limit, country, types, useCurrentLocation, proximity, boxed, onChange, accessToken }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [items, setItems] = useState([]);
    const [poiOptions, setPoiOptions] = useState<any>();
    let [prox, setProximity] = useState<string>('');
    let navigator = window.navigator;

    useEffect(() => {
        if (value) onSearch(value.text);

        if ('geolocation' in navigator && useCurrentLocation) {
            console.log('Available', navigator);
            getProximity();
        } else {
            console.log('Not Available');
        }
    }, []);

    const onSearch = (q: any) => {
        let search_string = encodeURI(q);
        let url = '';
        const mapboxPlaces = `https://api.mapbox.com/geocoding/v5/mapbox.places/${search_string}.json?limit=${limit}&proximity=${prox}&access_token=${accessToken}`;
        //should prob move this to a controller
        //better way to store this public access token?

        let paramaters = '';
        const paramatersArray: Array<string> = [];
        if (country) {
            paramatersArray.push(`country=${country}`);
        }
        if (types) {
            paramatersArray.push(`types=${types}`);
        }
        if (proximity) {
            setProximity(() => `proximity=${proximity.lng},${proximity.lat}`);
            // paramatersArray.push(`proximity=${proximity.lng},${proximity.lat}`)
        }
        if (boxed) {
            paramatersArray.push(`bbox=${boxed.first_bbox.lng},${boxed.first_bbox.lat},${boxed.second_bbox.lng},${boxed.second_bbox.lat}`);
        }

        paramaters = paramatersArray.map((param: string) => param).join('&');

        if (paramatersArray.length >= 1) {
            url = `${mapboxPlaces}&${paramaters}`;
        } else {
            url = mapboxPlaces;
        }

        setIsLoading(true);
        fetch(url)
            .then((response) => response.json())
            .then((data) => {
                setIsLoading(false);
                // console.log('onSearch REPSONSE', data);
                let items = data.features;
                if (items)
                    items = items.map((row: any) => {
                        let place_name = row.place_name;
                        return { ...row, id: place_name, text: place_name };
                    });
                let options = data.features
                    ?.filter((place: any) => place.place_type.includes('poi'))
                    .map((poi: any) => ({
                        id: poi.id,
                        center: poi.center,
                        name: poi.text,
                    }));
                setItems(items);
                setPoiOptions(options);
            });
    };

    const formatLocation = (value: any) => {
        // console.log('VALUE ', value);
        let location: any = {
            address: null,
            city: null,
            state: null,
            country: null,
            postal_code: null,
            text: null,
            coordinates: null,
            poi: null,
        };

        location.text = value.place_name || value.text;

        location.poi = poiOptions || [];

        location.coordinates = {
            lng: value.center[0],
            lat: value.center[1],
        };

        getLocationTypeObject(value, location);
        if (value.context) {
            for (let row of value.context) {
                getLocationTypeObject(row, location);
            }
        }

        location.json = value;

        console.log(location);
        return location;
    };

    const getLocationTypeObject = (value: any, location: any) => {
        let type = value.id.indexOf('.') !== -1 ? value.id.split('.')[0] : value.place_type[0];
        let newText = value.text ? value.text?.split(',')[0] : null;
        // if (value.text) {
        //     value.text = value.text?.split(',')[0];
        // }

        if (value.properties?.short_code) {
            value.short_code = value.properties?.short_code;
        }

        if (type === 'address') {
            location.address = `${value.address} ${value.original_text}`;
        }

        if (type === 'place') {
            location.city = value.original_text || newText;
        }

        if (type === 'postcode') {
            location.postal_code = value.original_text || newText;
        }

        if (type === 'region') {
            location.state = {
                id: value.short_code,
                text: value.original_text || newText,
            };
        }
        if (type === 'country') {
            location.country = {
                id: value.short_code,
                text: value.original_text || newText,
            };
        }
    };

    const getProximity = () => {
        navigator.geolocation.getCurrentPosition(
            function (position) {
                if (position.coords.longitude) {
                    setProximity(`${position.coords.longitude},${position.coords.latitude}`);
                }
            },
            (err: any) => {
                console.log(err);
            },
            { timeout: 2000 }
        );
    };

    return (
        <>
            <AutoComplete
                name={name}
                icon={icon}
                items={items || []}
                searchOptions={{
                    name: '',
                    placeholderText: 'Search for a location',
                }}
                isBusy={isLoading}
                value={value}
                required={required}
                onSearch={onSearch}
                limit={20}
                errors={errors}
                onChange={(v: any) => onChange && onChange(formatLocation(v))}
                clearOnBlur
                // showItemsOnFocus
            />
        </>
    );
};

LocationSearch.defaultProps = {
    required: false,
    limit: 5,
    icon: 'search',
    useCurrentLocation: false,
};
