import React, { useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import mapboxgl from "mapbox-gl";

interface Props {
    accessToken: string
    latitude?: string
    longitude?: string
    zoom?: number
    scrollZoom?: boolean
    displayInfo?: boolean 
    height?: string
}

interface MapState {
    lng: any | string
    lat: any | string
    zoom: number | undefined
    scrollZoom: boolean | undefined
}

export const Map: React.FC<Props> = ({ longitude, latitude, zoom, scrollZoom, displayInfo, height, accessToken }) => {
    const randomId = uuidv4();
    const [mapGuid, setMapGuid] = useState(randomId)
    const [map, setMap] = useState(null)
    const [state, setState] = useState<MapState>({
        lng: longitude,
        lat: latitude,
        zoom: zoom,
        scrollZoom: scrollZoom
    });
    const [marker, setMarker] = useState<any>()
    const mapContainer = useRef(null);

    useEffect(() => { 
        mapboxgl.accessToken = accessToken;
        const initializeMap = ({ setMap, mapContainer }: any) => {
            const lng = parseFloat(state.lng);
            const lat = parseFloat(state.lat);
    
            const map = new mapboxgl.Map({
                container: mapContainer.current,
                style: "mapbox://styles/mapbox/streets-v11",
                center: [lng, lat],
                zoom: state.zoom,
                scrollZoom: state.scrollZoom,
            });
            
            const markerEl = document.createElement("div");
            markerEl.className = "map-marker";
            markerEl.setAttribute("style", "position:absolute;top:0;left:0;");

            const marker = new mapboxgl.Marker().setLngLat([lng, lat]).addTo(map);
    
            map.on("load", () => {
                setMap(map);
                setMarker(marker)
                map.resize();
            });
        };

        if (!map) initializeMap({ setMap, mapContainer })
    }, [map])

    useEffect(() => {
        setState({
            lng: longitude,
            lat: latitude,
            zoom: zoom,
            scrollZoom: scrollZoom 
        })
    }, [longitude, latitude, zoom, scrollZoom])

    useEffect(() => {
        if (!(!map)) {
            updateMap(map)
        }
    }, [state])

    const updateMap = (map: any) => {
        const lng = parseFloat(state.lng);
        const lat = parseFloat(state.lat);
        // console.log('updateMap', state)

        map.flyTo({
            center: [lng, lat],
            zoom: state.zoom,
            // speed: 0.2,
            // curve: 1,
            essential: true
        })

        if (marker) {
            marker.remove()

            const markerEl = document.createElement("div");
            markerEl.className = "map-marker";
            markerEl.setAttribute("style", "position:absolute;top:0;left:0;");
    
            const newMarker = new mapboxgl.Marker().setLngLat([lng, lat]).addTo(map);
            setMarker(newMarker)
        }

    }
    
    return (
        <div id="map-container" className="map-container" style={{height: height}} >
            {   displayInfo &&
                <div className='sidebar-style' >
                    <div>Longitude: {state.lng} | Latitude: {state.lat} | Zoom: {state.zoom}</div>
                </div>
            }
            <div id={`map-${mapGuid}`} className="map"  ref={(el: any) => {mapContainer.current = el}}></div>
        </div>
    );
};

Map.defaultProps = {
    longitude: "-71.09",
    latitude: "42.36",
    zoom: 8,
    scrollZoom: false,
    displayInfo: false,
    height: '300px'
}