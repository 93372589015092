import React from 'react';
import {BasicColors} from '../components/GlobalEnums';

// This component needs to be used for all icons inside components even
export interface IconProps
{
    type: IconType,
    icon: string,
    padded?: boolean
    color?:BasicColors;
}
export type IconType = "light" | "regular" | "solid" | "brand" | "duotone";

 
export default class Icon extends React.Component<IconProps>
{
    getClasses(): string
    {
        var classes: string[] = [];

        if (this.props.type === "light")
            classes.push('fal');
        if (this.props.type === "regular")
            classes.push('far');
        if (this.props.type === "solid")
            classes.push('fas');
        if (this.props.type === "brand")
            classes.push('fab');
        if (this.props.type === "duotone")
            classes.push('fad');

        if (this.props.color)
            classes.push(`${this.props.color}-text`);

        return classes.join(' ');
    }

    render(): React.ReactNode 
    {

        return (
            <i className={`${this.getClasses()} fa-${(this.props.icon)} ${(this.props.padded) && 'm-1'}`} ></i>
        );

    }
}