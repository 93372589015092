import * as React from "react";

export type Alignment = "left" | "right" | "center";
export type ColumnSize = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
interface IColumnProps {
    showGutters?: boolean;
    colSize: ColumnSize;
    padded?: boolean;
    children: React.ReactNode;
    alignment?: Alignment;
}
export class LayoutColumn extends React.Component<IColumnProps> {
    public render() {
      return (
        <div className={`col-lg-${this.props.colSize} col-md-${this.props.colSize} text-${this.props.alignment || "left"}`}>
            <div className={`${(this.props.padded) && 'p-4'}`}>
                {this.props.children}
            </div>
        </div>
      );
    }
}