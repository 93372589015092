import React, { Fragment } from "react";
import { DropdownItem, DropdownItemProps } from "./Dropdown";

//@ts-ignore
import MediaQuery from "react-responsive";
import { NavItem } from "./Nav";
import { Modal } from "react-bootstrap";
import { Tooltip } from "..";

export interface ProfileProps {
	imageUrl: string;
	name: string;
	subtitle: string;
	role?: UserRoles;
	roleTooltipText?: string;
	impersonateImageUrl?: string;
	impersonateName?: string;
	impersonateSubtitle?: string;
	impersonateRole?: UserRoles;
	impersonateActive?: boolean;
	onExitImpersonation?: () => void;
	darkmode?: boolean;
	submenu?: DropdownItemProps[];
}

export type UserRoles = "default" | "admin" | "superadmin" | "helpdesk";

export interface ProfileState {
	expanded: boolean;
	darkMode: boolean;
	show: boolean;
}

export default class Profile extends React.Component<ProfileProps, ProfileState> {
	public static defaultProps = {
		imageUrl: "",
		name: "",
		subtitle: "",
		submenu: [],
		impersonateActive: false,
		impersonateName: "No Name",
		impersonateSubtitle: "No department",
	};

	constructor(props: ProfileProps) {
		super(props);

		this.state = {
			expanded: false,
			darkMode: false,
			show: false,
		};
	}

	getRole(): string {
		var classes: string[] = [];

		if (this.props.role) {
			if (this.props.role === "superadmin") classes.push("fas fa-crown");
			if (this.props.role === "admin") classes.push("far fa-crown");
			if (this.props.role === "helpdesk") classes.push("fas fa-headset");
		}

		return classes.join(" ");
	}

	getImpersonateRole(): string {
		var classes: string[] = [];

		if (this.props.impersonateRole === "superadmin") classes.push("fas fa-crown");
		if (this.props.impersonateRole === "admin") classes.push("far fa-crown");
		if (this.props.impersonateRole === "helpdesk") classes.push("fas fa-headset");

		return classes.join(" ");
	}

	switchMode = (): void => {
		const updatedState = { darkMode: !this.state.darkMode };

		this.setState(updatedState);

		if (updatedState.darkMode) {
			document.body.classList.add("dark");
			localStorage.setItem("hui.darkMode", "true");
		} else {
			document.body.classList.remove("dark");
			localStorage.setItem("hui.darkMode", "false");
		}
	};

	renderMobile(): React.ReactNode {
		return (
			<Fragment>
				{((this.props.submenu && this.props.submenu.length) || this.props.darkmode) && (
					<Modal className={"containerless"} show={this.state.show} onHide={() => this.setState({ show: false })}>
						<Modal.Header closeButton></Modal.Header>
						<Modal.Body>
							<div className={`nav nav-icons mobile-nav-list`}>
								{this.props.submenu &&
									this.props.submenu.length > 0 &&
									this.props.submenu.map((dropdownItem: DropdownItemProps, number: number) => (
										<NavItem
											key={number}
											icon={dropdownItem.icon}
											text={dropdownItem.text}
											iconOnly={false}
											onClick={() => {
												dropdownItem.onClick && dropdownItem.onClick();
												this.setState({ show: false });
											}}
										/>
									))}
								{this.props.darkmode && (
									<Fragment>
										{this.props.submenu && this.props.submenu.length > 0 && this.props.darkmode && <div className="dropdown-divider"></div>}
										{!this.state.darkMode ? (
											<NavItem
												icon={"moon"}
												text={"Go Dark"}
												iconOnly={false}
												onClick={() => {
													this.switchMode();
													this.setState({ show: false });
												}}
											/>
										) : (
											<NavItem
												icon={"sun"}
												text={"Go Light"}
												iconOnly={false}
												onClick={() => {
													this.switchMode();
													this.setState({ show: false });
												}}
											/>
										)}
									</Fragment>
								)}
							</div>
						</Modal.Body>
					</Modal>
				)}
				<div>
					<span className={this.getRole()}></span>
					<a
						className="profile"
						aria-haspopup={true}
						onClick={() => {
							this.setState({ show: true });
							setTimeout(() => {
								document.getElementsByClassName("modal-open")[0].classList.remove("modal-open");
							}, 100);
						}}>
						<img src={this.props.imageUrl} alt={this.props.name} />
						<div className="profile-details">
							<div className="name">{this.props.name}</div>
							<div className="subtitle">{this.props.subtitle}</div>
						</div>
					</a>
				</div>
				{this.props.impersonateActive && (
					<div className="impersonation">
						<span className={this.getImpersonateRole()}></span>
						<a className="profile" aria-haspopup={true} data-toggle="modal" data-target="#profileModal">
							<img src={this.props.imageUrl} alt={this.props.name} />
							<div className="profile-details">
								<div className="name">{this.props.name}</div>
								<div className="subtitle">{this.props.subtitle}</div>
							</div>
						</a>
					</div>
				)}
			</Fragment>
		);
	}

	renderDesktop(): React.ReactNode {
		let tagItems = {};

		if ((this.props.submenu && this.props.submenu.length > 0) || this.props.darkmode)
			tagItems = {
				href: "/#",
				id: "#navbarDropdownMenuLink",
				"data-toggle": "dropdown",
				"aria-haspopup": true,
				"aria-expanded": this.state.expanded,
				onClick: () => this.setState({ expanded: !this.state.expanded }),
			};

		return (
			<React.Fragment>
				<div className={`profile-wrapper ${this.props.impersonateActive && "disable-base-profile"}`}>
					<span className={this.getRole()}></span>
					<a className="profile base" {...tagItems}>
						<Tooltip text={this.props.roleTooltipText ? this.props.roleTooltipText : ""}>
							<img src={this.props.imageUrl} alt={this.props.name} />
						</Tooltip>
						<div className="profile-details">
							<div className="name">{this.props.name}</div>
							<div className="subtitle">{this.props.subtitle}</div>
						</div>
					</a>

					{this.props.impersonateActive && (
						<div className="impersonation">
							<span className={this.getImpersonateRole()}></span>
							<a className="profile" aria-haspopup={true} data-toggle="modal" data-target="#impersonateProfileModal">
								<img src={this.props.impersonateImageUrl} alt={this.props.impersonateName} />
								<div className="profile-details">
									<div className="name">{this.props.impersonateName}</div>
									<div className="subtitle">{this.props.impersonateSubtitle}</div>
								</div>
							</a>
							<div className="impersonation-exit-container">
								<a className="exit" onClick={() => this.props.onExitImpersonation && this.props.onExitImpersonation()}>
									<i className="fas fa-chevron-left"></i> Exit Impersonation
								</a>
							</div>
						</div>
					)}
					{((this.props.submenu && this.props.submenu.length > 0) || this.props.darkmode) && (
						<div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
							{this.props.submenu &&
								this.props.submenu.length > 0 &&
								this.props.submenu.map((dropdownItem: DropdownItemProps, number: number) => <DropdownItem {...dropdownItem} key={number} />)}
							{
								// this.props.darkmode
								// &&
								// <Fragment>
								//     {
								//         this.props.submenu && this.props.submenu.length > 0 && this.props.darkmode
								//         &&
								//         <div className="dropdown-divider"></div>
								//     }
								//     {
								//         !this.state.darkMode
								//             ?
								//             <DropdownItem onClick={this.switchMode} icon="moon" text="Go Dark" />
								//             :
								//             <DropdownItem onClick={this.switchMode} icon="sun" text="Go Light" />
								//     }
								// </Fragment>
							}
						</div>
					)}
				</div>
			</React.Fragment>
		);
	}

	render(): React.ReactNode {
		return (
			<React.Fragment>
				<MediaQuery maxWidth={576}>{this.renderMobile()}</MediaQuery>
				<MediaQuery minWidth={577}>{this.renderDesktop()}</MediaQuery>
			</React.Fragment>
		);
	}
}
