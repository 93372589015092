import React, { Fragment } from 'react';
import { AtlasTheme } from '../components/Template';
import { Button, ButtonState, ButtonType } from './Button';

// This component needs to be used for all icons inside components even
export interface LoaderProps {
    type?: 'default' | 'pageNotFound' | 'noAccess' | 'offline';
    name: string;
    exit: boolean;
    message?: string;
    contactEmail: string;
    theme?: AtlasTheme;
    isLoading?: boolean;
}

export default class Loader extends React.Component<LoaderProps> {
    public static defaultProps = {
        name: 'Application',
        exit: false,
        hasAccess: true,
        pageNotFound: false,
        isLoading: false,
    };

    getClasses(): string {
        var classes: string[] = [];

        if (this.props.theme == 'medical-dark') classes.push('medical-dark');
        if (this.props.theme == 'medical') classes.push('medical');
        if (this.props.exit) classes.push('loader-page fade-out');
        else {
            classes.push('loader-page');
        }

        return classes.join(' ');
    }

    render(): React.ReactNode {
        if (!this.props.type || this.props.type === 'default') {
            return (
                <div className={`${this.getClasses()}`}>
                    <div className='logo-container '>
                        <div className={this.props.exit ? 'fade-out' : 'slide-in-blurred-top'}>
                            <svg viewBox='0 0 127 162' version='1.1'>
                                <title>ATLAS</title>
                                <g id='New-Atlas-Apps' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                                    <g id='Splash-Screen' transform='translate(-124.000000, -513.000000)'>
                                        <g id='Group' transform='translate(124.556641, 513.000000)'>
                                            <path
                                                d='M8.015625,161 L9.40722656,155.674805 L17.5712891,155.674805 L18.9628906,161 L27.3496094,161 L18.6103516,134.225586 L8.73925781,134.225586 L0,161 L8.015625,161 Z M16.1425781,150.126953 L10.8359375,150.126953 L13.4150391,140.404297 L13.5634766,140.404297 L16.1425781,150.126953 Z M43.6591797,161 L43.6591797,140.478516 L50.7099609,140.478516 L50.7099609,134.225586 L28.7412109,134.225586 L28.7412109,140.478516 L35.7919922,140.478516 L35.7919922,161 L43.6591797,161 Z M71.7695312,161 L71.7695312,154.74707 L61.2675781,154.74707 L61.2675781,134.225586 L53.4003906,134.225586 L53.4003906,161 L71.7695312,161 Z M81.4550781,161 L82.8466797,155.674805 L91.0107422,155.674805 L92.4023438,161 L100.789062,161 L92.0498047,134.225586 L82.1787109,134.225586 L73.4394531,161 L81.4550781,161 Z M89.5820312,150.126953 L84.2753906,150.126953 L86.8544922,140.404297 L87.0029297,140.404297 L89.5820312,150.126953 Z M114,161.463867 C121.384766,161.463867 125.689453,157.957031 125.689453,152.446289 C125.689453,148.104492 122.980469,145.748047 117.228516,144.708984 L114.538086,144.226562 C111.866211,143.744141 110.71582,143.168945 110.71582,141.962891 C110.71582,140.756836 111.829102,139.791992 114.018555,139.791992 C116.170898,139.791992 117.84082,140.738281 117.896484,142.371094 L125.132812,142.371094 C125.095703,137.083008 121.087891,133.761719 113.925781,133.761719 C107.561523,133.761719 102.922852,137.157227 102.922852,142.538086 C102.922852,146.694336 105.835938,149.384766 111.161133,150.331055 L113.851562,150.813477 C116.857422,151.351562 117.896484,151.889648 117.896484,153.151367 C117.896484,154.46875 116.449219,155.433594 114.092773,155.433594 C111.866211,155.433594 109.992188,154.505859 109.695312,152.891602 L102.458984,152.891602 C102.477539,158.365234 107.060547,161.463867 114,161.463867 Z'
                                                id='ATLAS'
                                                fill='#FFFFFF'
                                                fillRule='nonzero'
                                            ></path>
                                            <g id='Logo' transform='translate(15.443359, 0.000000)'>
                                                <path
                                                    d='M56.1226252,0 L97,107 L63.6614853,107 L58.0492228,92.4166667 L30.2392055,92.4166667 L24.4594128,107 L16.8367876,107 L0,80.25 L30.2392055,0 L56.1226252,0 Z M43.8929188,46.5 L36.7728843,66 L51.2642487,66 L43.8929188,46.5 Z'
                                                    id='Combined-Shape'
                                                    fill='#FFFFFF'
                                                ></path>
                                                <polygon id='Path-4' fill='#DE4141' points='0 80.25 22 81 16.7984869 107'></polygon>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                        </div>
                        <div className='fade-in'>{this.props.name}</div>
                        {this.props.isLoading && 
                            <Button type={ButtonType.Ghost} isBusy text='' state={ButtonState.Disabled} />
                        }
                    </div>
                </div>
            );
        }

        if (this.props.type === 'noAccess') {
            return (
                <div className='loader-page denied'>
                    <div className='logo-container'>
                        <svg viewBox='0 0 127 162' version='1.1'>
                            <title>ATLAS</title>
                            <g id='New-Atlas-Apps' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                                <g id='Splash-Screen' transform='translate(-124.000000, -513.000000)'>
                                    <g id='Group' transform='translate(124.556641, 513.000000)'>
                                        <path
                                            d='M8.015625,161 L9.40722656,155.674805 L17.5712891,155.674805 L18.9628906,161 L27.3496094,161 L18.6103516,134.225586 L8.73925781,134.225586 L0,161 L8.015625,161 Z M16.1425781,150.126953 L10.8359375,150.126953 L13.4150391,140.404297 L13.5634766,140.404297 L16.1425781,150.126953 Z M43.6591797,161 L43.6591797,140.478516 L50.7099609,140.478516 L50.7099609,134.225586 L28.7412109,134.225586 L28.7412109,140.478516 L35.7919922,140.478516 L35.7919922,161 L43.6591797,161 Z M71.7695312,161 L71.7695312,154.74707 L61.2675781,154.74707 L61.2675781,134.225586 L53.4003906,134.225586 L53.4003906,161 L71.7695312,161 Z M81.4550781,161 L82.8466797,155.674805 L91.0107422,155.674805 L92.4023438,161 L100.789062,161 L92.0498047,134.225586 L82.1787109,134.225586 L73.4394531,161 L81.4550781,161 Z M89.5820312,150.126953 L84.2753906,150.126953 L86.8544922,140.404297 L87.0029297,140.404297 L89.5820312,150.126953 Z M114,161.463867 C121.384766,161.463867 125.689453,157.957031 125.689453,152.446289 C125.689453,148.104492 122.980469,145.748047 117.228516,144.708984 L114.538086,144.226562 C111.866211,143.744141 110.71582,143.168945 110.71582,141.962891 C110.71582,140.756836 111.829102,139.791992 114.018555,139.791992 C116.170898,139.791992 117.84082,140.738281 117.896484,142.371094 L125.132812,142.371094 C125.095703,137.083008 121.087891,133.761719 113.925781,133.761719 C107.561523,133.761719 102.922852,137.157227 102.922852,142.538086 C102.922852,146.694336 105.835938,149.384766 111.161133,150.331055 L113.851562,150.813477 C116.857422,151.351562 117.896484,151.889648 117.896484,153.151367 C117.896484,154.46875 116.449219,155.433594 114.092773,155.433594 C111.866211,155.433594 109.992188,154.505859 109.695312,152.891602 L102.458984,152.891602 C102.477539,158.365234 107.060547,161.463867 114,161.463867 Z'
                                            id='ATLAS'
                                            fill='#FFFFFF'
                                            fillRule='nonzero'
                                        ></path>
                                        <g id='Logo' transform='translate(15.443359, 0.000000)'>
                                            <path
                                                d='M56.1226252,0 L97,107 L63.6614853,107 L58.0492228,92.4166667 L30.2392055,92.4166667 L24.4594128,107 L16.8367876,107 L0,80.25 L30.2392055,0 L56.1226252,0 Z M43.8929188,46.5 L36.7728843,66 L51.2642487,66 L43.8929188,46.5 Z'
                                                id='Combined-Shape'
                                                fill='#FFFFFF'
                                            ></path>
                                            <polygon id='Path-4' fill='#DE4141' points='0 80.25 22 81 16.7984869 107'></polygon>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </svg>
                        <div>{this.props.name}</div>
                        <br />
                        <p>
                            <strong>
                                You do not have access to {this.props.name} please contact <a href={`mailto:${this.props.contactEmail}`}>{this.props.contactEmail}</a>
                            </strong>
                        </p>
                    </div>
                </div>
            );
        }

        if (this.props.type === 'pageNotFound') {
            return (
                <div className='loader-page denied'>
                    <div className='logo-container '>
                        <svg viewBox='0 0 127 162' version='1.1'>
                            <title>ATLAS</title>
                            <g id='New-Atlas-Apps' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                                <g id='Splash-Screen' transform='translate(-124.000000, -513.000000)'>
                                    <g id='Group' transform='translate(124.556641, 513.000000)'>
                                        <path
                                            d='M8.015625,161 L9.40722656,155.674805 L17.5712891,155.674805 L18.9628906,161 L27.3496094,161 L18.6103516,134.225586 L8.73925781,134.225586 L0,161 L8.015625,161 Z M16.1425781,150.126953 L10.8359375,150.126953 L13.4150391,140.404297 L13.5634766,140.404297 L16.1425781,150.126953 Z M43.6591797,161 L43.6591797,140.478516 L50.7099609,140.478516 L50.7099609,134.225586 L28.7412109,134.225586 L28.7412109,140.478516 L35.7919922,140.478516 L35.7919922,161 L43.6591797,161 Z M71.7695312,161 L71.7695312,154.74707 L61.2675781,154.74707 L61.2675781,134.225586 L53.4003906,134.225586 L53.4003906,161 L71.7695312,161 Z M81.4550781,161 L82.8466797,155.674805 L91.0107422,155.674805 L92.4023438,161 L100.789062,161 L92.0498047,134.225586 L82.1787109,134.225586 L73.4394531,161 L81.4550781,161 Z M89.5820312,150.126953 L84.2753906,150.126953 L86.8544922,140.404297 L87.0029297,140.404297 L89.5820312,150.126953 Z M114,161.463867 C121.384766,161.463867 125.689453,157.957031 125.689453,152.446289 C125.689453,148.104492 122.980469,145.748047 117.228516,144.708984 L114.538086,144.226562 C111.866211,143.744141 110.71582,143.168945 110.71582,141.962891 C110.71582,140.756836 111.829102,139.791992 114.018555,139.791992 C116.170898,139.791992 117.84082,140.738281 117.896484,142.371094 L125.132812,142.371094 C125.095703,137.083008 121.087891,133.761719 113.925781,133.761719 C107.561523,133.761719 102.922852,137.157227 102.922852,142.538086 C102.922852,146.694336 105.835938,149.384766 111.161133,150.331055 L113.851562,150.813477 C116.857422,151.351562 117.896484,151.889648 117.896484,153.151367 C117.896484,154.46875 116.449219,155.433594 114.092773,155.433594 C111.866211,155.433594 109.992188,154.505859 109.695312,152.891602 L102.458984,152.891602 C102.477539,158.365234 107.060547,161.463867 114,161.463867 Z'
                                            id='ATLAS'
                                            fill='#FFFFFF'
                                            fillRule='nonzero'
                                        ></path>
                                        <g id='Logo' transform='translate(15.443359, 0.000000)'>
                                            <path
                                                d='M56.1226252,0 L97,107 L63.6614853,107 L58.0492228,92.4166667 L30.2392055,92.4166667 L24.4594128,107 L16.8367876,107 L0,80.25 L30.2392055,0 L56.1226252,0 Z M43.8929188,46.5 L36.7728843,66 L51.2642487,66 L43.8929188,46.5 Z'
                                                id='Combined-Shape'
                                                fill='#FFFFFF'
                                            ></path>
                                            <polygon id='Path-4' fill='#DE4141' points='0 80.25 22 81 16.7984869 107'></polygon>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </svg>
                        <div className='fade-in'>{this.props.name}</div>
                        <br></br>
                        <h4>
                            <strong>Page not found</strong>
                        </h4>
                    </div>
                </div>
            );
        }

        if (this.props.type === 'offline') {
            return (
                <div className='loader-page denied'>
                    <div className='logo-container '>
                        <svg viewBox='0 0 127 162' version='1.1'>
                            <title>ATLAS</title>
                            <g id='New-Atlas-Apps' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                                <g id='Splash-Screen' transform='translate(-124.000000, -513.000000)'>
                                    <g id='Group' transform='translate(124.556641, 513.000000)'>
                                        <path
                                            d='M8.015625,161 L9.40722656,155.674805 L17.5712891,155.674805 L18.9628906,161 L27.3496094,161 L18.6103516,134.225586 L8.73925781,134.225586 L0,161 L8.015625,161 Z M16.1425781,150.126953 L10.8359375,150.126953 L13.4150391,140.404297 L13.5634766,140.404297 L16.1425781,150.126953 Z M43.6591797,161 L43.6591797,140.478516 L50.7099609,140.478516 L50.7099609,134.225586 L28.7412109,134.225586 L28.7412109,140.478516 L35.7919922,140.478516 L35.7919922,161 L43.6591797,161 Z M71.7695312,161 L71.7695312,154.74707 L61.2675781,154.74707 L61.2675781,134.225586 L53.4003906,134.225586 L53.4003906,161 L71.7695312,161 Z M81.4550781,161 L82.8466797,155.674805 L91.0107422,155.674805 L92.4023438,161 L100.789062,161 L92.0498047,134.225586 L82.1787109,134.225586 L73.4394531,161 L81.4550781,161 Z M89.5820312,150.126953 L84.2753906,150.126953 L86.8544922,140.404297 L87.0029297,140.404297 L89.5820312,150.126953 Z M114,161.463867 C121.384766,161.463867 125.689453,157.957031 125.689453,152.446289 C125.689453,148.104492 122.980469,145.748047 117.228516,144.708984 L114.538086,144.226562 C111.866211,143.744141 110.71582,143.168945 110.71582,141.962891 C110.71582,140.756836 111.829102,139.791992 114.018555,139.791992 C116.170898,139.791992 117.84082,140.738281 117.896484,142.371094 L125.132812,142.371094 C125.095703,137.083008 121.087891,133.761719 113.925781,133.761719 C107.561523,133.761719 102.922852,137.157227 102.922852,142.538086 C102.922852,146.694336 105.835938,149.384766 111.161133,150.331055 L113.851562,150.813477 C116.857422,151.351562 117.896484,151.889648 117.896484,153.151367 C117.896484,154.46875 116.449219,155.433594 114.092773,155.433594 C111.866211,155.433594 109.992188,154.505859 109.695312,152.891602 L102.458984,152.891602 C102.477539,158.365234 107.060547,161.463867 114,161.463867 Z'
                                            id='ATLAS'
                                            fill='#FFFFFF'
                                            fillRule='nonzero'
                                        ></path>
                                        <g id='Logo' transform='translate(15.443359, 0.000000)'>
                                            <path
                                                d='M56.1226252,0 L97,107 L63.6614853,107 L58.0492228,92.4166667 L30.2392055,92.4166667 L24.4594128,107 L16.8367876,107 L0,80.25 L30.2392055,0 L56.1226252,0 Z M43.8929188,46.5 L36.7728843,66 L51.2642487,66 L43.8929188,46.5 Z'
                                                id='Combined-Shape'
                                                fill='#FFFFFF'
                                            ></path>
                                            <polygon id='Path-4' fill='#DE4141' points='0 80.25 22 81 16.7984869 107'></polygon>
                                        </g>
                                    </g>
                                </g>
                            </g>
                        </svg>
                        <div className='fade-in'>{this.props.name}</div>
                        <br></br>
                        <h4>
                            <strong>Offline</strong>
                        </h4>
                        {this.props.message && <p>{this.props.message}</p>}
                        {this.props.contactEmail && (
                            <Fragment>
                                <br />
                                <p>
                                    please contact <a href='mailto:{this.props.contactEmail}'>{this.props.contactEmail}</a>
                                </p>
                            </Fragment>
                        )}
                    </div>
                </div>
            );
        }
    }
}
