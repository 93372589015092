import React, { ReactElement, FC, Fragment, useEffect } from "react";
import ReactDOM from "react-dom";
import Text from "../Text";
import { AtlasTheme } from "./AtlasPage";
import FocusTrap from "focus-trap-react";

export interface TemplateModalProps {
	name: string;
	show?: boolean;
	onClose?: any;
	header: ReactElement<Text>; // WHAT IS THIS, should this not use HUI's Heading
	footer: any;
	body: any;
	bodyAlignment: TemplateModalAlignment;
	size?: TemplateModalSize;
	imageUrl?: string;
	containerless?: boolean;
	padded?: boolean;
	autoHeight?: boolean;
	theme?: AtlasTheme;
	noExternalClose?: boolean;
}

export enum TemplateModalAlignment {
	Right,
	Center,
	Left,
}

export enum TemplateModalSize {
	Small = "sm",
	Default = "",
	Large = "lg",
	ExtraLarge = "xl",
}

const Backdrop: FC<any> = (props) => {
	useEffect(() => {
		document.getElementById("root")?.classList.add("modal-open");
		return document.getElementById("root")?.classList.remove("modal-open");
		//eslint-disable-next-line
	}, []);

	return props.show ? (
		<a
			tabIndex={!props.onClose ? -1 : 0}
			data-dismiss="modal"
			aria-label="Close"
			onKeyDown={(e: any) => {
				if (e.keyCode === 13 && props.clicked) {
					if (props.onClose) {
						props.clicked();
						e.preventDefault();
					}
				}
			}}
			onClick={(e: any) => {
				if (props.onClose && !props.noExternalClose) {
					document.getElementById("root")?.classList.remove("modal-open");
					props.clicked();
					e.preventDefault();
				}
			}}>
			<div className={"Backdrop"}></div>
		</a>
	) : null;
};

export default class TemplateModal extends React.Component<TemplateModalProps> {
	public static defaultProps = {
		size: TemplateModalSize.Default,
		autoHeight: false,
		padded: true,
		theme: "default",
	};

	private _container: any;
	private _child: any;

	componentDidMount() {
		this._container = document.createElement("div");
		document.documentElement.appendChild(this._container);

		this._child = ReactDOM.render(this.renderComponent(), this._container);
	}

	componentDidUpdate() {
		ReactDOM.hydrate(this.renderComponent(), this._container);
		if (!this._child) return;

		this._child.setState({});
	}

	componentWillUnmount() {
		ReactDOM.unmountComponentAtNode(this._container);
		document.documentElement.removeChild(this._container);
	}

	getClasses(): string {
		var classes: string[] = [];

		// I strongly doubt wether these IFs need to be here
		if (this.props.show) classes.push("show");
		if (this.props.containerless) classes.push("containerless");
		if (this.props.autoHeight) classes.push("auto-height");

		if (this.props.theme !== "default") {
			if (this.props.theme) classes.push(this.props.theme);
		}

		return classes.join(" ");
	}

	render(): React.ReactNode {
		return null;
	}

	renderComponent(): React.ReactElement {
		if (this.props.show && !this.props.noExternalClose) {
			document.addEventListener("keydown", (e: any) => {
				if (e.keyCode === 27) {
					this.props.onClose && this.props.onClose();
				}
			});

			this._container.className = "modal-wrapper modal-show";
		} else {
			document.getElementById("root")?.classList.remove("modal-open");
			this._container.className = "modal-wrapper";
		}

		return (
			<React.Fragment>
				{this.props.show && (
					<Fragment>
						<FocusTrap focusTrapOptions={{ allowOutsideClick: true, fallbackFocus: "div" }}>
							<div tabIndex={-1} role="dialog" aria-modal="true" aria-labelledby={`${this.props.name}-label`}>
								<div>
									<Backdrop
										show={this.props.show}
										noExternalClose={this.props.noExternalClose}
										clicked={() => {
											this.props.onClose && this.props.onClose();
										}}
										onClose={this.props.onClose}
									/>
									<div className={`modal fade ${this.getClasses()}`} id={`${this.props.name}`}>
										<div className={`modal-dialog modal-${this.props.size}`} role="document">
											<div className={`modal-content`}>
												<div className={`modal-header`}>
													{this.props.header}
													{this.props.onClose && (
														<button
															type="button"
															className={`close`}
															data-dismiss="modal"
															aria-label="Close"
															onClick={() => {
																document.getElementById("root")?.classList.remove("modal-open");
																this.props.onClose();
															}}>
															<i className={`far fa-times`}></i>
														</button>
													)}
												</div>
												<div className={`modal-body text-${this.props.bodyAlignment} ${this.props.padded ? "" : "nopadding"}`}>{this.props.body}</div>
												{this.props.imageUrl && <img alt="" src={`${this.props.imageUrl}`} />}
												<div className={`modal-footer`}>{this.props.footer}</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</FocusTrap>
					</Fragment>
				)}
			</React.Fragment>
		);
	}
}
