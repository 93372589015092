import React from 'react';

export interface MetricCardProps
{
    label: string,
    value: number,
    isLoading?:boolean
}
 
export default class MetricCard extends React.Component<MetricCardProps>
{

    getClasses(): string
    {
        var classes: string[] = [];

        if (this.props.isLoading)
            classes.push('loading');

        return classes.join(' ');
    }
    render(): React.ReactNode 
    {
        return (
            <div className={`metric-card card py-3 text-center ${this.getClasses()}`}>
                <h4>{this.props.value}</h4>
                <div>{this.props.label}</div>
            </div>
        );

    }
}