import React, { Fragment } from "react";
import NavItemDropDown, { NavItemDropDownState } from "./NavItemDropdown";
import { Link } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import Icon, { IconType } from "../Icon";
import { BasicColors } from "../GlobalEnums";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Badge, { BadgeProps } from "../Badge/Badge";

export interface NavItemProps {
	icon: string;
	iconOnly: boolean;
	text: string;
	state?: NavItemState;
    type?: NavItemType;
    badge?: BadgeProps;
	badgeDot?: boolean; //indicates only a dot.
	to?: string;
	onClick?: any;
	properties?: { [key: string]: string };
	[key: string]: any;
	iconStyle?: IconType;
	template?: any;
	children?: NavItemProps[];
}

export enum NavItemState {
	None,
	Disabled,
	Active,
	Invisible,
}

export enum NavItemType {
	Default,
	Dropdown,
}

export default class NavItem extends React.Component<NavItemProps> {
	private id = uuidv4();

	public static defaultProps = {
		icon: "",
		text: "",
		type: NavItemType.Default,
		state: NavItemState.None,
		badge: "",
		badgeDot: false,
	};

	getClasses(): string {
		var classes: string[] = [];

		if (this.props.state === NavItemState.Active) classes.push("active");
		else if (this.props.state === NavItemState.Disabled) classes.push("disabled");

		if (this.props.badgeDot) classes.push("badgedot");

		return classes.join(" ");
	}

	getIconClasses(): string {
		var classes: string[] = [];

		if (this.props.state === NavItemState.Active) classes.push("fas");
		else if (this.props.iconStyle === "duotone") classes.push("fad");
		else if (this.props.iconStyle === "regular") classes.push("far");
		else if (this.props.iconStyle === "solid") classes.push("fas");
		else {
			classes.push("fal");
		}

		return classes.join(" ");
	}

	getDropDownState(): NavItemDropDownState {
		if (this.props.state === NavItemState.Active) return NavItemDropDownState.Active;
		else if (this.props.state === NavItemState.Disabled) return NavItemDropDownState.Disabled;

		return NavItemDropDownState.None;
	}

	getAriaDisabledState(): boolean | undefined {
		if (this.props.state === NavItemState.Disabled) return true;

		return undefined;
	}

	onKeyDown(e: React.KeyboardEvent<HTMLAnchorElement>) {
		if (e.keyCode == 13 && this.props.onClick) {
			this.props.onClick();
		}
	}

	renderDropdown(children: NavItemProps[], tabIndex: any): React.ReactNode {
		return (
			<div className="dropdown-menu">
				{children.map((child: NavItemProps) => {
					return (
						<Fragment>
							{child.to && (
								<Link
									{...tabIndex}
									{...child.properties}
									className={`nav-link dropdown-item ${this.getClasses()}`}
									title={child.text}
									to={child.to}
									onClick={() => child.onClick && child.onClick()}>
									{child.icon !== "" && <i className={`${this.getIconClasses()} fa-${child.icon}`}></i>}
									{child.text}
                                    {this.props.badge && <Badge {...this.props.badge} />}
									{child.badgeDot && (
										<span className={`badge badge-pill badge-warning`}>
											<div className={`p-1`}></div>
										</span>
									)}
								</Link>
							)}
							{!child.to && (
								<a
									{...tabIndex}
									{...child.properties}
									className={`nav-link dropdown-item ${this.getClasses()}`}
									title={child.text}
									onClick={() => child.onClick && child.onClick()}
									onKeyDown={(e: React.KeyboardEvent<HTMLAnchorElement>) => this.onKeyDown(e)}>
									{child.icon !== "" && <i className={`${this.getIconClasses()} fa-${child.icon}`}></i>}
									{child.text}
                                    {this.props.badge && <Badge {...this.props.badge} />}
									{child.badgeDot && (
										<span className={`badge badge-pill badge-warning`}>
											<div className={`p-1`}></div>
										</span>
									)}
								</a>
							)}
						</Fragment>
					);
				})}
			</div>
		);
	}

	render(): React.ReactNode {
		if (this.props.template) return <React.Fragment>{this.props.template(this.props)}</React.Fragment>;

		let tabindex = this.props.state === NavItemState.Disabled ? { tabIndex: -1 } : { tabIndex: 0 };

		if (!this.props.type) {
			return (
				// MENU BAR wants the <LI> to be active
				//NAVBARS want the <A> to be active, we need conditional thing here (it's a bootstrap issue)
				this.props.iconOnly ? (
					<OverlayTrigger placement={"bottom"} trigger={["hover", "focus"]} overlay={<Tooltip id={`tooltip-${this.id}`}>{this.props.text ? this.props.text : ""}</Tooltip>}>
						<li id={this.id} className={`nav-item ${this.getClasses()}`} aria-disabled={this.getAriaDisabledState()}>
							{this.props.to ? (
								<Link
									{...tabindex}
									{...this.props.properties}
									className={`nav-link ${this.getClasses()}`}
									title={this.props.text}
									to={this.props.to}
									onClick={() => this.props.onClick && this.props.onClick()}>
									{this.props.icon !== "" && <i className={`${this.getIconClasses()} fa-${this.props.icon}`}></i>}
									{this.props.text}
									{this.props.badge && <Badge {...this.props.badge} />}
									{this.props.badgeDot && (
										<span className={`badge badge-pill badge-warning`}>
											<div className={`p-1`}></div>
										</span>
									)}
								</Link>
							) : (
								<a
									{...tabindex}
									{...this.props.properties}
									className={`nav-link ${this.getClasses()}`}
									title={this.props.text}
									onClick={() => this.props.onClick && this.props.onClick()}
									onKeyDown={(e: React.KeyboardEvent<HTMLAnchorElement>) => this.onKeyDown(e)}>
									{this.props.icon !== "" && <i className={`${this.getIconClasses()} fa-${this.props.icon}`}></i>}
									{this.props.text}
                                    {this.props.badge && <Badge {...this.props.badge} />}
									{this.props.badgeDot && (
										<span className={`badge badge-pill badge-warning`}>
											<div className={`p-1`}></div>
										</span>
									)}
								</a>
							)}
							{this.props.children && this.renderDropdown(this.props.children, tabindex)}
						</li>
					</OverlayTrigger>
				) : (
					<li id={this.id} className={`nav-item ${this.getClasses()}`} aria-disabled={this.getAriaDisabledState()}>
						{this.props.to ? (
							<Link
								{...tabindex}
								{...this.props.properties}
								className={`nav-link ${this.getClasses()}`}
								title={this.props.text}
								to={this.props.to}
								onClick={() => this.props.onClick && this.props.onClick()}>
								{this.props.icon !== "" && <i className={`${this.getIconClasses()} fa-${this.props.icon}`}></i>}
								{this.props.text}
								{this.props.badge && <Badge {...this.props.badge} />}
								{this.props.badgeDot && (
									<span className={`badge badge-pill badge-warning`}>
										<div className={`p-1`}></div>
									</span>
								)}
							</Link>
						) : (
							<a
								{...tabindex}
								{...this.props.properties}
								className={`nav-link ${this.getClasses()}`}
								title={this.props.text}
								onClick={() => this.props.onClick && this.props.onClick()}
								onKeyDown={(e: React.KeyboardEvent<HTMLAnchorElement>) => this.onKeyDown(e)}>
								{this.props.icon !== "" && <i className={`${this.getIconClasses()} fa-${this.props.icon}`}></i>}
								{this.props.text}
                                {this.props.badge && <Badge {...this.props.badge} />}
								{this.props.badgeDot && (
									<span className={`badge badge-pill badge-warning`}>
										<div className={`p-1`}></div>
									</span>
								)}
							</a>
						)}
						{this.props.children && this.renderDropdown(this.props.children, tabindex)}
					</li>
				)
			);
		} else {
			return <NavItemDropDown {...this.props} state={this.getDropDownState()} />;
		}
	}
}
