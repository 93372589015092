import React, { Fragment } from 'react';

export type SkeletonLoaderType = 'default' | 'table' | 'list';

interface SkeletonLoaderProps {
    type?: SkeletonLoaderType;
}

export default class SkeletonLoader extends React.Component<SkeletonLoaderProps>
{
    render(): React.ReactNode {

        if (this.props.type === "table") {
            return (
                <Fragment>
                    <div className={`skeleton-loader rtable loading rtable--4cols rtable--collapse}`}>
                        <div style={{ order: 0 }} className="rtable-cell skeleton"><div></div></div>
                    </div>
                    <div className={`skeleton-loader rtable loading rtable--4cols rtable--collapse}`}>
                        <div style={{ order: 0 }} className="rtable-cell skeleton"><div></div></div>
                        <div style={{ order: 0 }} className="rtable-cell skeleton"><div></div></div>
                        <div style={{ order: 0 }} className="rtable-cell skeleton"><div></div></div>
                        <div style={{ order: 0 }} className="rtable-cell skeleton"><div></div></div>

                        <div style={{ order: 0 }} className="rtable-cell skeleton"><div></div></div>
                        <div style={{ order: 0 }} className="rtable-cell skeleton"><div></div></div>
                        <div style={{ order: 0 }} className="rtable-cell skeleton"><div></div></div>
                        <div style={{ order: 0 }} className="rtable-cell skeleton"><div></div></div>

                        <div style={{ order: 0 }} className="rtable-cell skeleton"><div></div></div>
                        <div style={{ order: 0 }} className="rtable-cell skeleton"><div></div></div>
                        <div style={{ order: 0 }} className="rtable-cell skeleton"><div></div></div>
                        <div style={{ order: 0 }} className="rtable-cell skeleton"><div></div></div>

                        <div style={{ order: 0 }} className="rtable-cell skeleton"><div></div></div>
                        <div style={{ order: 0 }} className="rtable-cell skeleton"><div></div></div>
                        <div style={{ order: 0 }} className="rtable-cell skeleton"><div></div></div>
                        <div style={{ order: 0 }} className="rtable-cell skeleton"><div></div></div>
                    </div>
                </Fragment>
            )
        }

        if (this.props.type === "list") {
            return (
                <div className="loading list-group skeleton-loader">
                    <div className="skeleton list-group-item">&nbsp;</div>
                    <div className="skeleton list-group-item">&nbsp;</div>
                    <div className="skeleton list-group-item">&nbsp;</div>
                    <div className="skeleton list-group-item">&nbsp;</div>
                </div>
            );
        }

        return (
            <div className="loading list-group skeleton-default skeleton-loader">
                <div className="skeleton list-group-item">&nbsp;</div>
                <div className="skeleton list-group-item">&nbsp;</div>
                <div className="skeleton list-group-item">&nbsp;</div>
                <div className="skeleton list-group-item">&nbsp;</div>
            </div>
        );
    }
}