import React from "react";

interface GoogleAnalyticsProps {
    gaId: string;
}

export default class GoogleAnalytics extends React.Component<GoogleAnalyticsProps>
{
    constructor(props: GoogleAnalyticsProps)
    {
        super(props);
    }

    componentDidMount () {
        const script = document.createElement("script");
        script.src = `https://www.googletagmanager.com/gtag/js?id=${this.props.gaId}`
        script.async = false;
        document.body.appendChild(script);

        const script2 = document.createElement("script");
        script2.innerHTML = `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag("js", new Date());gtag("config", "${this.props.gaId}");`;
        document.body.appendChild(script2);
    }

    render(): React.ReactNode
    {
        return null;       
    }
}
