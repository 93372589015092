import React from 'react';

import TableRow, { TableRowProps } from './TableRow';

interface TableProps
{
    type: string, //will add more to this later
    header: string[],
    rows: TableRowProps[],
    columns: number,
    flush?: boolean,
    isLoading?: boolean
}

export default class Table extends React.Component<TableProps>
{
    render(): React.ReactNode
    {

        if (this.props.isLoading) 
        {
            return (

                <div className={`rtable loading rtable--5cols rtable--collapse}`}>
                    <div style={{ order: 0 }} className="rtable-cell skeleton"><div></div></div>
                    <div style={{ order: 0 }} className="rtable-cell skeleton"><div></div></div>
                    <div style={{ order: 0 }} className="rtable-cell skeleton"><div></div></div>
                    <div style={{ order: 0 }} className="rtable-cell skeleton"><div></div></div>
                    <div style={{ order: 0 }} className="rtable-cell skeleton"><div></div></div>

                    <div style={{ order: 0 }} className="rtable-cell skeleton"><div></div></div>
                    <div style={{ order: 0 }} className="rtable-cell skeleton"><div></div></div>
                    <div style={{ order: 0 }} className="rtable-cell skeleton"><div></div></div>
                    <div style={{ order: 0 }} className="rtable-cell skeleton"><div></div></div>
                    <div style={{ order: 0 }} className="rtable-cell skeleton"><div></div></div>

                    <div style={{ order: 0 }} className="rtable-cell skeleton"><div></div></div>
                    <div style={{ order: 0 }} className="rtable-cell skeleton"><div></div></div>
                    <div style={{ order: 0 }} className="rtable-cell skeleton"><div></div></div>
                    <div style={{ order: 0 }} className="rtable-cell skeleton"><div></div></div>
                    <div style={{ order: 0 }} className="rtable-cell skeleton"><div></div></div>
                </div>)

        }
        else 
        {
            return (

                <div className={`rtable rtable--${this.props.columns}cols rtable--collapse ${(this.props.flush) && 'flush'}`}>
                    {
                        this.props.header.map((item: any, number: number) =>
                        {
                            return <div style={{ order: 0 }} key={number} className="rtable-cell header">{item}</div>
                        })
                    }
                    {
                        this.props.rows.map((item: TableRowProps, number: number) => (
                            <TableRow {...item} key={number} />
                        ))
                    }
                </div>
            );
        }
    }
}